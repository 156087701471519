import { HomaDivider } from '@global/assets';
import {
  SectionWrapper,
  PinkInfoHeading,
  SectionTitle,
  StyledInfoHeading,
  DividerImg,
  InfoTextWithBottomMargin as InfoText,
  BoldInfoText,
  UnderlinedText,
  UnderlinedPinkText,
} from './styles';

export const TermsSection = () => (
  <SectionWrapper>
    <StyledInfoHeading>
      Homa Non-Fungible Tokens Airdrop
    </StyledInfoHeading>
    <PinkInfoHeading>Terms & Conditions</PinkInfoHeading>

    <InfoText>
      These NFTs Airdrop Terms and Conditions (the “
      <strong>
        Terms and
        Conditions
      </strong>
      ”) are the terms and
      conditions upon which Homa Games, a French
      <em>société par actions simplifiée</em>
      ,
      registered under number 853
      747 644 with the Trade and Companies Register of Paris, whose registered office is
      located at 7, rue de Madrid,
      75008 Paris ( “
      <strong>Homa</strong>
      ”) will allow the Beneficiary to receive, claim
      and/or mint NFTs.
    </InfoText>
    <InfoText>
      These Terms and Conditions are a legally binding agreement between Homa, You and
      any subsequent
      beneficiary and/or purchaser of the NFTs.
    </InfoText>
    <InfoText>
      Please read these Terms and Conditions carefully as they set out and explain Your
      legal rights and
      obligations including waivers of rights and limitation of liabilities. By benefiting,
      purchasing, claiming and/or
      minting NFTs, You acknowledge and agree that You have read this document and that You
      agree to be bound by
      it.
    </InfoText>
    <BoldInfoText>
      We strongly recommend that you do not try to benefit from, purchase, claim
      and/or mint NFTs if you are
      not an expert in cryptocurrencies, digital assets and blockchain technology. Even if you
      have knowledge of the
      technology, you should consult your own lawyer, accountant, and other professionals
      whereso required. Homa will
      not be responsible in any way for any loss incurred by you resulting from you
      benefiting, purchasing, claiming
      and/or minting NFTs.
    </BoldInfoText>
    <BoldInfoText>
      By benefiting, claiming and/or minting NFTs, using or otherwise accessing the
      Website, you shall be
      deemed to acknowledge that you do so with full consent and full knowledge of these Terms
      and Conditions having
      first obtained legal advice as to the full force and effect of these Terms and
      Conditions. Your participation will
      be deemed to be your undertaking that you satisfy this requirement and shall be deemed
      to be your unequivocal and
      express consent to be bound by these Terms and Conditions.
    </BoldInfoText>
    <InfoText>
      Only after that the NFTs Airdrop has been completed and the project described in
      the Information
      implemented, the Beneficiary of NFTs may benefit from certain Access Rights in the sole
      discretion of
      Homa.
    </InfoText>

    <SectionTitle>BACKGROUND</SectionTitle>
    <InfoText>
      Please visit the Website and read the Information for yourself. The Website and
      the Information provide a non-binding description, for information purposes only, of the
      Access Rights that may be developed and offered by Homa.
    </InfoText>

    <SectionTitle>DEFINITIONS</SectionTitle>
    <InfoText>
      <strong>Access Rights</strong>
      {' '}
      – is defined in Article 1.3 of the present Terms
      and Conditions.
    </InfoText>
    <InfoText>
      <strong>Agreement</strong>
      {' '}
      – means these Terms &amp; Conditions exclusively and no
      other. This Agreement governs your participation in the Primary Transactions on the
      Website as well as Secondary Transactions between Transferees and Transferors (as
      defined below).
    </InfoText>
    <InfoText>
      <strong>Airdrop</strong>
      {' '}
      – means the process of distribution of NFTs for free to
      certain persons who have participated in the Airdrop through the Website or any
      alternate means authorized by Homa in its own discretion.
    </InfoText>
    <InfoText>
      <strong>Beneficiary</strong>
      {' '}
      – means the beneficiary of the NFTs, “beneficiary”,
      “you”, “You” “your”, or “yourself”.
    </InfoText>
    <InfoText>
      <strong>Blockchain</strong>
      {' '}
      – means the underlying blockchain technology on which
      the NFTs are minted,
      {' '}
      <em>i.e.</em>
      {' '}
      Ethereum. By extension, it also refers to every
      blockchain technology Homa or the Beneficiary may use.
    </InfoText>
    <InfoText>
      <strong>Ethers (ETH) </strong>
      – means the native tokens of the Ethereum
      blockchain.
    </InfoText>
    <InfoText>
      <strong>Information</strong>
      {' '}
      – means the description on a non-binding basis of the
      NFTs and the Access Rights. The Information may be modified and changed from time to
      time by Homa .
    </InfoText>
    <InfoText>
      <strong>NFTs</strong>
      {' '}
      – means, in the absence of clear legal definition under
      French law as of the date of this Agreement, an element recorded into a blockchain that
      represents an intangible asset such as an image, a character, a digital land or work of
      art. For the purpose of the present Agreement, NFTs refer to the collection of 7,000
      non-fungible tokens called “Valentines”, built on the Ethereum blockchain. Each NFT is
      digital in nature and is not linked to nor is granted together with (i)&nbsp;any items
      or representations that have physical dimensions such as mass or volume, or (ii) any
      Access Rights as of the time of Airdrop.
    </InfoText>
    <InfoText>
      <strong>NFTs Airdrop</strong>
      {' '}
      – means the present NFTs airdrop.
      {' '}
      <strong>
        For the
        avoidance of doubt, the airdropped NFTs will be randomly determined by the Smart
        Contract (as defined below) among the 7,000 NFTs covered by this NFTs Airdrop .
      </strong>
    </InfoText>
    <InfoText>
      <strong>Primary Transaction</strong>
      {' '}
      – means a transaction facilitated through the
      Website in which an NFT is first attributed to the Beneficiary.
    </InfoText>
    <InfoText>
      <strong>Prohibited Person</strong>
      {' '}
      – means a person who is a resident or national
      of the following countries: Afghanistan, Albania, Angola, Azerbaijan, Bosnia
      Herzegovina, Bahamas, Barbados, Burma, Botswana, Burkina Faso, Burundi, Cayman Islands,
      Cambodia, Cameroon, Crimea (Ukraine), Chad, China, Congo, Democratic Republic of Congo,
      Cuba, Ethiopia, Eritrea, Fiji, Palau, Ghana, Guinea, Guinea-Bissau, Haiti, Iran, Iraq,
      Jamaica, Jordan, Lao People&resco;s Democratic Republic, Uganda, Liberia, Libya, Madagascar,
      Mali, Malta, Morocco, Mozambique, Nicaragua, Nigeria, North Korea, Pakistan, Panama,
      Philippines, Puerto Rico, Russia, Senegal, Somalia, Sri Lanka, Sudan, Syria, Tajikistan,
      Trinidad and Tobago, Turkey, Turkmenistan, United States, Uzbekistan, Vanuatu,
      Venezuela, Virgin Islands, Yemen, Zimbabwe and any resident or national of a country
      otherwise subject to a U.S. Government embargo, or that has been designated by the U.S.
      Government as a terrorist-supporting country, and (ii) are not listed on any U.S.
      Government list of prohibited or restricted parties.
    </InfoText>
    <InfoText>
      <strong>Secondary Transaction</strong>
      {' '}
      – means any transaction in which an NFT is
      sold by an owner to another owner, or is otherwise transferred, distributed, or
      disseminated (regardless of whether consideration is paid) in any manner that is not a
      Primary Transaction. Such Secondary Transactions will be subject to the charging of a
      commission of 10% (ten percent) of the consideration paid, the amount of which may be
      modified at any time by Homa in its sole discretion.
    </InfoText>
    <InfoText>
      <strong>Homa </strong>
&nbsp;– means Homa Games, a French
      {' '}
      <em>
        société par actions
        simplifiée
      </em>
      , registered under number 853 747 644 with the Trade and Companies
      Register of Paris, whose registered office is located at 7, rue de Madrid, 75008 Paris,
      and “Us”, “We” or “Ours” in these Terms and Conditions.
    </InfoText>
    <InfoText>
      <strong>Terms and Conditions</strong>
      {' '}
      – means the terms and conditions contained
      in this Agreement.
    </InfoText>
    <InfoText>
      <strong>This Jurisdiction</strong>
      {' '}
      – means France.
    </InfoText>
    <InfoText>
      <strong>Wallet</strong>
      {' '}
      – refers to an Ethereum network compatible wallet address
      and a wallet that is compatible with the Website.
    </InfoText>
    <InfoText>
      <strong>Website</strong>
      {' '}
      – means Homa Games Non-Fungible Tokens Airdrop website
      available at https://homagang.xyz/. The access and use of the Website are subject to the
      separate terms of the Website available at: https://www.homagames.com/terms.
    </InfoText>

    <SectionTitle>RECITALS</SectionTitle>
    <InfoText>
      WHEREAS, the NFTs are not investment tools and therefore this Agreement shall not
      be considered as or represent any kind of offer to purchase and/or sale of any financial
      security or security tool or any kind of investment option.
    </InfoText>
    <InfoText>
      WHEREAS, Homa will allow the Aidrop of NFTs as well as claiming and minting the
      NFTs.
    </InfoText>
    <InfoText>WHEREAS, the Beneficiary agrees to abide by these Terms and Conditions.</InfoText>
    <InfoText>
      NOW THEREFORE, in mutual consideration of the covenants and agreements contained
      herein, Homa and the Beneficiary agree as follows:
    </InfoText>

    <SectionTitle>INTERPRETATION</SectionTitle>
    <InfoText>Unless the context of this Agreement otherwise requires:</InfoText>
    <InfoText>
      - words of any gender include all other genders;
      <br />
      - words using the singular or plural also include the plural or singular respectively;
      <br />
      - the terms “hereof”, “hereby”, “hereto” and derivatives of similar words refer to this
      entire
      Agreement;
      <br />
      - the word “including” shall mean “including without limitation”.
    </InfoText>

    <InfoText>
      The Terms and Conditions are only in respect of the Airdrop, mint and/or claim of
      NFTs. Any potential future use of the NFTs in connection with the Access Rights that may
      be provided by the Website and/or Homa may be governed by other applicable terms and
      conditions.
    </InfoText>

    <SectionTitle>1. ACCESS RIGHTS</SectionTitle>

    <InfoText>
      <strong>1.1. </strong>
      The Beneficiary acknowledges and agrees that the NFTs do not
      have attached to them any rights, neither express nor implied, other than the ownership
      of the personal property
      rights to the NFTs
      (e.g., the right to freely sell, transfer, or otherwise dispose of that NFT) that are
      attributed randomly by the
      Smart Contract (as defined below). No other rights of any kind or nature with
      respect to the NFTs or any
      image or other intellectual property (i.e. all intellectual property rights of any kind
      including but not limited
      to copyright (including rights in computer software), trade mark, service mark, design,
      patent, trade secret,
      code, trade, business, domain or company names, moral rights, rights in confidential
      information, know-how or
      other proprietary rights (whether or not any of these are registered and including any
      application for
      registration) and all rights or forms of protection of a similar nature or having
      equivalent or similar effect to
      any of these which may subsist anywhere in the world) associated with, related to, or
      linked to, an NFT are
      granted or licensed to the Beneficiary.
    </InfoText>
    <InfoText>
      <strong>1.2. </strong>
      For the avoidance of doubt, without limiting Article 1.1,
      nothing contained in this
      Agreement will be deemed to grant Beneficiary any rights in or to any image or other
      intellectual property
      associated with, related to, or linked to, an NFT, including any right to use such image
      or intellectual property
      for any non-personal or commercial purposes, or to create any derivative works of such
      image or intellectual
      property. Notwithstanding the foregoing, to the limited extent that an image is
      displayed as a result of the
      operation of the smart contract related to the NFT in connection with Beneficiary’s use
      of an NFT in accordance
      with this Agreement, Beneficiary will have the right to display such image for
      Purchaser’s own personal,
      non-commercial purpose for so long as Beneficiary owns the NFT.
    </InfoText>
    <InfoText>
      <strong>1.3. </strong>
      Each NFT may, following the date hereof, entitle the
      Beneficiary to certain tangible
      or intangible rights, benefits, interests, preferences, or privileges herein offered
      from time to time by
      Homa or third parties in their respective sole discretion (“
      <strong>
        Access
        Rights
      </strong>
      ”). Access Rights,
      if any, will be offered pursuant to separate terms (whether written or unwritten)
      published from time to time by
      Homa, or the applicable third party.
    </InfoText>
    <InfoText>
      <strong>1.4.</strong>
      {' '}
      The Beneficiary, upon claiming and/or minting NFTs,
      acknowledges and agrees that
      they will not have any rights in shares, security, ownership or any such other rights or
      any future rights to
      receive them, nor any intellectual property rights in Homa and its
      affiliates.
    </InfoText>

    <SectionTitle>2. THE NFTS</SectionTitle>
    <InfoText>The NFTs are not intended to be any form of:</InfoText>
    <InfoText>
      - security;
      <br />
      - commodity;
      <b />
      - financial instrument; or
      <b />
      - investment.
    </InfoText>
    <InfoText>
      Beneficiary should have no expectation of rights to Homa ’s (nor any other
      company) earnings nor other
      interests in Homa (or any other company) such as dividends or governance rights
      and no access to its
      shareholders’ general meetings.
    </InfoText>
    <InfoText>
      Beneficiary should have no expectation of price appreciation of the NFTs
      whatsoever.
    </InfoText>
    <InfoText>
      Nothing in these Terms and Conditions shall be considered to constitute a
      prospectus of any sort
      whatsoever or a solicitation of investment of any kind. These Terms and Conditions are
      not an offering to buy or
      sell any securities in any jurisdiction.
    </InfoText>
    <InfoText>
      Beneficiary declares that Homa has not solicited him in any form whatsoever to
      claim and/or mint
      NFTs.
    </InfoText>

    <SectionTitle>3. DEVELOPMENT</SectionTitle>
    <InfoText>
      <strong>3.1.</strong>
      {' '}
      In claiming and/or minting the NFTs, the Beneficiary
      acknowledges, agrees and fully
      understands that the Access Rights are still in the development stage.
    </InfoText>
    <InfoText>
      <strong>3.2. </strong>
      The Beneficiary acknowledges and agrees that updates may
      give rise to changes in the
      Access Rights that may be attached in the future to the NFTs.
    </InfoText>
    <InfoText>
      <strong>3.3. </strong>
      The Beneficiary acknowledges that Homa shall have full
      discretion to make further
      airdrops and/or sales of NFTs or fungible tokens in the future, in whatever form, using
      any blockchain.
    </InfoText>

    <SectionTitle>4. OFFER</SectionTitle>
    <InfoText>
      <strong>4.1.</strong>
      {' '}
      Homa offers for aidrop to the Beneficiary NFT(s) in
      accordance with these Terms and
      Conditions.
    </InfoText>
    <InfoText>
      <strong>4.2.</strong>
      {' '}
      The Beneficiary agrees to claim and mint NFTs offered from
      Homa in accordance with
      these Terms and Conditions.
    </InfoText>
    <InfoText>
      <strong>4.3.</strong>
      {' '}
      The Beneficiary will, within fourty (40) working days from
      the mint of the NFTs by
      him, receive its purchased NFTs on its public Ethereum address communicated to
      Homa.
    </InfoText>
    <InfoText>
      <strong>4.4.</strong>
      {' '}
      Homa will publicly release updates and information on the
      Website in respect of the
      development of the Access Rights that may be granted.
    </InfoText>
    <InfoText>
      <strong>4.5.</strong>
      {' '}
      The determination of the number of available NFTs as well as
      the different
      categories of NFTs and their level of scarcity is and will be, at all times, at the
      entire discretion of
      Homa.
    </InfoText>

    <SectionTitle>5. PAYMENT</SectionTitle>

    <InfoText>
      <strong>5.1.</strong>
      {' '}
      Beneficiary hereby agrees to claim and mint the NFT(s) for
      free and in accordance
      with the procedures set forth on the Website.
    </InfoText>

    <SectionTitle>6. NFTs AIRDROP PROCEDURE</SectionTitle>

    <InfoText>
      <strong>6.1.</strong>
      {' '}
      Homa reserves the right to amend and modify this NFTs
      Airdrop procedure at any time.
    </InfoText>
    <InfoText>
      <strong>6.2.</strong>
      {' '}
      The Beneficiary should register its interest in the Airdrop
      of NFTs. This will be
      done through the Website.
    </InfoText>
    <InfoText>
      <strong>6.3.</strong>
      {' '}
      Homa reserves the right, in its sole discretion, to limit
      the number of NFTs that
      may be claimed and/or minted by any single person, directly and/or
      indirectly. Beneficiary may
      only claim, mint and receive a maximum number of NFTs per Wallet as determined
      from time to time by Homa in
      its sole discretion. As of the date of the present Agreement, Beneficiary may only
      claim, mint and/or
      receive a maximum of two (2) NFTs if they are an employee of Homa or one of its
      subsidiaries and may only,
      claim, mint and receive a maximum of one (1) NFT in any other case.
    </InfoText>
    <InfoText>
      <strong>6.4.</strong>
      {' '}
      Beneficiaries may only receive NFT(s) on their Metamask
      non-custodial Wallets.
    </InfoText>
    <InfoText>
      <strong>6.5.</strong>
      {' '}
      Homa gives no guarantee and makes no representations
      whatsoever as to the
      future value of the NFTs.
    </InfoText>

    <SectionTitle>7. DELIVERY AND WALLET</SectionTitle>

    <InfoText>
      <strong>7.1.</strong>
      {' '}
      The NFTs to be minted by the Beneficiary will be locked in a
      smart contract
      deployed on the Ethereum Blockchain (the “
      <strong>Smart Contract</strong>
      ”)
    </InfoText>
    <InfoText>
      <strong>7.2. </strong>
      By using his Ethereum blockchain address, the Beneficiary
      will be able to claim,
      mint and receive the NFTs by interacting with the Smart Contract directly on the
      Ethereum Blockchain. The
      minted NFTs will be automatically transferred from the Smart Contract’s address to the
      Ethereum Blockchain
      address of the Beneficiary.
    </InfoText>
    <InfoText>
      <strong>7.3. </strong>
      The Beneficiary shall pay the transaction fees (such as Gas
      Fees) required for the
      interaction(s) with the Smart Contract (
      <em>i.e.</em>
      {' '}
      claiming, minting, and receiving
      the NFTs). Gas Fees
      fund the network of computers that run the decentralized Ethereum network, meaning that
      the Beneficiary will
      need to pay a Gas Fee for each transaction that occurs via the Ethereum network. Homa
      does not have any
      insight into or control over these payments or transactions, nor does Homa have the
      ability to reverse any
      transactions. Accordingly, Homa will have no liability to You or to any third party for
      any claims or damages
      that may arise as a result of any transactions on the NFTs that You engage
      in.
    </InfoText>
    <InfoText>
      <strong>7.4. </strong>
      The Beneficiary expressly acknowledges that the NFTs will
      not be sent directly by
      Homa and may only be received through interaction(s) with the Smart Contract. Therefore,
      the Beneficiary
      understands that it is their responsibility to claim and mint the NFTs, and that the
      loss of their
      cryptographic private key (or other equivalent credential) corresponding to the Ethereum
      blockchain address
      mentioned above and giving access to the NFTs may impair their ability to receive the
      NFTs. Homa shall not be
      responsible for the loss incurred by the Beneficiary in the event of a loss of such
      cryptographic private key
      (or other equivalent credential).
    </InfoText>
    <InfoText>
      <strong>7.5. </strong>
      For the avoidance of doubt, Homa ’s obligation to deliver
      the NFTs will be
      materialized by the deployment of the Smart Contract, as long as, by interacting with
      the Smart Contract, the
      Beneficiary is able to claim, mint and receive their NFTs,
      {' '}
      <strong>
        provided that the
        Beneficiary shall have
        claimed and minted all of their NFTs by November 17th, 2022; after this deadline,
        the possibility to claim
        and mint NFTs will no longer exist.
        {' '}
      </strong>
      .
    </InfoText>
    <InfoText>
      <strong>7.6. </strong>
      The Beneficiary must keep their wallet, private key, and
      login access to the
      Website safe and not share them with any other person.
    </InfoText>
    <InfoText>
      <strong>7.7. </strong>
      All Secondary Transactions are subject to the following
      terms: the NFTs transferee
      (the “
      <strong>Transferee</strong>
      ”) shall, by purchasing or otherwise receiving the
      NFTs, be deemed to accept
      all of the terms of this Agreement as a “Beneficiary” hereof and the NFTs transferor
      (the
      “
      <strong>Transferor</strong>
      ”) shall provide notice to the Transferee of this Agreement,
      including a link or
      other method by which the terms of this Agreement can be accessible by the Transferee,
      and shall cause the
      Transferee to agree to the terms of this Agreement. Beneficiary further acknowledges and
      agrees that all
      Secondary Transactions will be conducted on the Ethereum blockchain (or any other
      blockchain indicated by
      Homa), the blockchain network governing the NFTs, and the Beneficiary will be required
      to make or receive
      payments exclusively through Purchaser’s cryptocurrency wallet.
    </InfoText>

    <SectionTitle>8. ACKNOWLEDGEMENT OF RISK</SectionTitle>

    <InfoText>
      <strong>8.1.</strong>
      {' '}
      You represent and warrant that You fully understand and have
      significant
      experience of cryptocurrencies, digital assets, NFTs, blockchain systems and services,
      and You fully
      understand the risks associated with the trading of cryptocurrencies and digital assets,
      the claim and mint of
      NFTs, as well as the mechanics related to the use of such cryptocurrencies, digital
      assets and NFTs (including
      with respect to their storage and exchange). In particular, Beneficiary acknowledges and
      agrees that he has
      sufficient understanding of the functionality, usage, storage, transmission mechanisms
      and other material
      characteristics of non-fungible cryptographic tokens, token wallets and other token
      storage mechanisms, public
      and private key management, blockchain technology, and blockchain-based software systems
      to understand this
      Agreement. Beneficiary understands, acknowledges and agrees that such knowledge allows
      them to appreciate the
      implications and risks of claiming and/or minting the NFTs herein.
    </InfoText>
    <InfoText>
      <strong>8.2.</strong>
      {' '}
      You acknowledge that trading markets are extremely volatile
      and shift quickly in
      terms of liquidity, market depth, and trading dynamics.
    </InfoText>
    <InfoText>
      <strong>8.3.</strong>
      {' '}
      The Beneficiary acknowledges and agrees that he is aware
      that cryptocurrencies,
      digital assets and NFTs operate in a network without intermediaries and do not benefit
      from legal tender,
      unlike currencies issued by central banks. Their value is not guaranteed.
    </InfoText>
    <InfoText>
      <strong>8.4.</strong>
      {' '}
      There is no guarantee of liquidity with respect to the NFTs
      as well as the
      existence of a secondary market. As a result, the Beneficiary may not be able to sell
      their NFTs easily or at
      prices that would provide them with a valuation gain.
    </InfoText>
    <InfoText>
      <strong>8.5.</strong>
      {' '}
      You represent that You have read, understood and agreed to
      all the risk factors
      attached as
      {' '}
      <UnderlinedText>Annex</UnderlinedText>
      {' '}
      to these Terms and
      Conditions
    </InfoText>
    <InfoText>
      <strong>8.6.</strong>
      {' '}
      You acknowledge and agree that each minted unique NFT may
      have a different value
      in any secondary market. No request for replacement of the minted NFT will be accepted
      by Homa. Moreover, Homa
      may in the future proceed to any modification of the NFTs that he will consider, in good
      faith, as an
      improvement of the NFTs, for example to allow the possibility of using them in different
      metaverses or games,
      which the Beneficiary acknowledges and accepts in advance.
    </InfoText>
    <InfoText>
      <strong>8.7.</strong>
      {' '}
      You represent and agree that Homa may decide, in its sole
      discretion, to move the
      NFTs on a different blockchain than Ethereum, in particular if such new blockchain is
      considered as more
      suitable to support the NFTs.
    </InfoText>

    <SectionTitle>9. REPRESENTATION, WARRANTIES AND COVENANTS</SectionTitle>
    <InfoText>
      You hereby agree that by claiming and/or minting NFTs, you warrant and represent
      the following to be
      true:
    </InfoText>
    <InfoText>
      <strong>9.1.</strong>
      {' '}
      You are not a Prohibited Person. If you claim and/or mint
      the NFTs, we are deeming
      the claim and/or mint to be your undertaking that you are not a Prohibited Person.
    </InfoText>
    <InfoText>
      <strong>9.2.</strong>
      {' '}
      You are not a Politically Exposed Person (PEP).
    </InfoText>
    <InfoText>
      <strong>9.3.</strong>
      {' '}
      You are legally permitted to claim, mint and hold the NFTs
      in your
      jurisdiction.
    </InfoText>
    <InfoText>
      <strong>9.4.</strong>
      {' '}
      You have not received advice from Homa regarding the claim
      and/or mint of
      NFTs.
    </InfoText>
    <InfoText>
      <strong>9.5.</strong>
      {' '}
      You are of legal age to claim and/or mint the NFTs and are
      not aware of any legal
      reason preventing you from doing so in your jurisdiction or in this
      Jurisdiction.
    </InfoText>
    <InfoText>
      <strong>9.6.</strong>
      {' '}
      You are of sound mind and have the requisite power and
      authority to understand and
      agree to these Terms and Conditions and to carry out and perform the obligations as set
      out
      hereunder.
    </InfoText>
    <InfoText>
      <strong>9.7.</strong>
      {' '}
      You will not use the NFTs and/or Website for unlawful or
      fraudulent
      purposes.
    </InfoText>
    <InfoText>
      <strong>9.8.</strong>
      {' '}
      You will not impair the proper operation of the
      Website.
    </InfoText>
    <InfoText>
      <strong>9.9.</strong>
      {' '}
      You hereby acknowledge that you are solely responsible for
      your own tax liability,
      if any, in relation to the NFTs which may adversely affect You.
    </InfoText>
    <InfoText>
      <strong>9.10.</strong>
      {' '}
      You hereby acknowledge that You have sole responsibility
      for the appreciation or
      depreciation of the value of Your minted NFTs.
    </InfoText>
    <InfoText>
      <strong>9.11.</strong>
      {' '}
      You will hold a suitable online or offline wallet or
      storage mechanism to support
      the NFTs.
    </InfoText>
    <InfoText>
      <strong>9.12. </strong>
      You represent and warrant that you will not use the NFT to
      violate any right of
      Homa, its licensors or any third party, including without limitation, any right of
      privacy, publicity,
      copyright, trademark, or patent.
    </InfoText>

    <SectionTitle>10. LIMITATION OF LIABILITY</SectionTitle>

    <InfoText>
      <strong>10.1.</strong>
      {' '}
      To the maximum extent permitted by law and any regulatory
      provisions to which We
      are subject to, You acknowledge and agree that the entire risk arising out of Your
      participation in and
      claiming and/or minting of the NFTs is entirely Yours and remains with You at all times,
      before, during and
      after such risk may apply.
    </InfoText>
    <InfoText>
      <strong>10.2. </strong>
      To the maximum extent permitted by law, neither We nor any
      Disclaimed Party
      involved in the Airdrop of NFTs as well as their creation, production, mint or delivery
      will be liable, in any
      way, for any incidental, special, exemplary, legal (reasonable costs) or consequential
      losses or damages, this
      to include
      {' '}
      <em>inter alia</em>
      {' '}
      loss of profits, loss of data (excluding personal data),
      loss of goodwill,
      service interruption, computer damage or system failure or the cost of substitute
      products or services, or for
      any damages whatsoever of a personal nature, arising out of or in connection with these
      NFTs whether or not
      they are based on warranty, contract, tort, this to include negligence, product
      liability or any other such
      thing, whether or not We have been informed of such matters and the possibility of any
      such damages or alike,
      this also, even if a limited remedy set forth and contained in these Terms and
      Conditions has failed or could
      fail, because of essential purpose.
    </InfoText>
    <InfoText>
      <strong>10.3.</strong>
      {' '}
      Under no circumstances Homa will be liable for any loss or
      damage incurred by the
      Beneficiary in respect of any breach of security in respect of their password,
      credentials and secret codes.
      Neither Homa nor the Disclaimed Parties will be liable or responsible to the Beneficiary
      for any loss, and
      takes no responsibility for any use of the NFTs, including but not limited to any
      damages, losses or claims
      arising from (i) user error such as incorrectly constructed transactions, forgotten
      passwords or mistyped
      wallet addresses, (ii) corrupted files, (iii) server failure, (iv) unauthorized access
      to the NFTs or (v) any
      third-party activities, including without limitation the use of viruses, phishing,
      bruteforcing, hacking or
      other means of attack. Neither Homa nor any Disclaimed Party will be responsible for any
      kind of failure,
      abnormal behaviour of software (e.g., smart contract, wallet), blockchains or any other
      features of the NFTs.
      Neither Homa nor any Disclaimed Party is responsible for casualties due to late report
      by developers or
      representatives (or no report at all) of any issues with the blockchain supporting the
      NFTs, including
      technical node issues, forks or any other issues having fund losses as a result.
    </InfoText>
    <InfoText>
      <strong>10.4.</strong>
      {' '}
      You agree to release, indemnify, and hold Homa and Our
      contractors,
      sub-contractor, affiliates, subsidiaries and agents and their officers, directors,
      employees and agents,
      harmless from and against any claims, liabilities, damages, losses, and expenses,
      including, without
      limitation, reasonable legal and accounting fees, arising out of or in any way connected
      with Your violation
      of any of these Terms and Conditions.
    </InfoText>

    <SectionTitle>11. DISCLAIMERS</SectionTitle>

    <InfoText>
      <strong>11.1. </strong>
      If you choose to claim and/or mint NFTs, you do so entirely
      at your own
      risk.
    </InfoText>
    <InfoText>
      <strong>11.2.</strong>
      {' '}
      You acknowledge and agree that we do not have any
      obligations, whatsoever, or
      under any circumstances, to conduct any checks not required by applicable law, including
      but not limited to,
      background checks, on any Beneficiary.
    </InfoText>
    <InfoText>
      <strong>11.3.</strong>
      {' '}
      You hereby acknowledge and agree that the NFTs and the
      Website are made available
      “as is” and “as available”, with no commercial warranties of any kind whatsoever and
      that, without prejudice
      to the generality of the foregoing, we make no warranty regarding, and shall have no
      responsibility for, the
      accuracy, availability, reliability, security, fitness for purpose or performance of the
      same. Homa explicitly
      disclaims on behalf of itself, all other entities, persons and parties that are on in
      the future may provide
      any Access Rights, and each of their respective officers, employees, directors,
      representatives and agents
      (collectively the “
      <strong>Disclaimed Parties</strong>
      ”), any and all guarantees,
      warranties and conditions,
      implied or express, including without limitation any implied warranty of
      merchantability, fitness for a
      particular purpose, quiet enjoyment or non-infringement, to the maximum extent permitted
      by applicable law,
      and any warranties arising out of trade or usage or dealing. Homa, on behalf of itself
      and the Disclaimed
      Parties, makes no guarantee, representation or warranty that the NFTs will meet
      Beneficiary’s requirements, or
      the NFTs will be available on an uninterrupted, error-free, or secure basis.
    </InfoText>
    <InfoText>
      <strong>11.4.</strong>
      {' '}
      We make no warranty that the NFTs Airdrop , including but
      not restricted to, any
      information provided via any communication will meet Your requirements or will be
      available or made available
      in an uninterrupted, secure, or error-free basis.
    </InfoText>
    <InfoText>
      <strong>11.5.</strong>
      {' '}
      No advice or information, whether oral or in writing,
      obtained from Homa or the
      Website will create any warranty herein unless expressely provided as such..
    </InfoText>
    <InfoText>
      <strong>11.6.</strong>
      {' '}
      Homa does not guarantee, warrant, or represent that any
      item downloaded from the
      Internet on the Website is free from viruses. You are explicitly responsible for
      implementing appropriate
      procedures, anti-virus protection and software to protect Yourself and your data. In
      this regard, Homa is not
      liable for any damage caused to Your equipment due to the use of the Website or through
      material posted on or
      linked to any website. You should also be aware that SMS and email services are
      vulnerable to spoofing and
      phishing attacks and should use care in reviewing messages purporting to originate from
      us.
    </InfoText>
    <InfoText>
      <strong>11.7.</strong>
      {' '}
      Homa bears no responsibility and cannot provide any
      warranty whatsoever in
      relation to the success or otherwise of the NFTs. The NFTs may have no value.
    </InfoText>
    <InfoText>
      <strong>11.8.</strong>
      {' '}
      The Website may contain links to third-party websites,
      advertisers, services,
      special offers, or other events or activities that are not owned or controlled by Homa.
      We do not endorse or
      assume any responsibility for any such third-party sites, information, materials,
      products, or services. If
      you access a third-party website from the Website, you do so at your own risk, and you
      understand that these
      Terms and Conditions do not apply to your use of such external websites. You expressly
      relieve us from all
      liability arising from your use of any third-party website, service, or content.
    </InfoText>
    <InfoText>
      <strong>11.9.</strong>
      {' '}
      The regulatory status of the NFTs remains unclear or
      unsettled in many
      jurisdictions. Indeed, some jurisdictions may implement legislation that will affect
      NFTs. Homa accepts
      no liability in relation to regulatory action that may be taken or which may affect Homa
      in the future.
      Furthermore, Homa may cease operations in a jurisdiction which takes regulatory action,
      changes laws adversely
      or makes it commercially undesirable to trade in such jurisdiction.
    </InfoText>
    <InfoText>
      <strong>11.10. </strong>
      The Beneficiary represents and warrants that he (i) is
      claiming and/or minting
      the NFTs for personal enjoyment purposes only, and (ii) is not claiming and/or minting
      any NFT with the intent
      or expectation of profits from any appreciation in value or otherwise from the NFTs or
      any Access Rights that
      may from time to time be granted by Homa or third parties.
    </InfoText>

    <SectionTitle>12. CHANGES TO THIS AGREEMENT</SectionTitle>

    <InfoText>
      <strong>12.1.</strong>
      {' '}
      Homa may cease operations in a jurisdiction which takes
      regulatory action,
      changes laws adversely or makes it commercially undesirable or illegal to trade in such
      jurisdiction.
      Accordingly, these Terms and Conditions may be revised or updated by us from time to
      time. and you should
      check the Terms and Conditions for updates. You can determine when the Terms and
      Conditions were last
      revised by referring to the “Last Revised” legend at the top of this
      document.
    </InfoText>
    <InfoText>
      <strong>12.2.</strong>
      {' '}
      Each time you claim and/or mint NFTs, You signify Your
      acceptance of the
      then-current Terms and Conditions.
    </InfoText>
    <InfoText>
      <strong>12.3.</strong>
      {' '}
      Any changes in these Terms and Conditions take effect upon
      posting.
    </InfoText>
    <InfoText>
      <strong>12.4.</strong>
      {' '}
      You understand and agree that We may discontinue or
      restrict Your use of our
      Website at any time for any or no reason with or without notice and without reference to
      You.
    </InfoText>

    <SectionTitle>13. MISCELLANEOUS</SectionTitle>

    <InfoText>
      <strong>13.1.</strong>
      {' '}
      Any reference to statute, enactment, order or regulation or
      other similar
      instrument made in this Agreement, shall be construed as a reference to such instrument
      as it is in force for
      the time being taking into account any amendment, extension, application, consolidation
      or
      re-enactment.
    </InfoText>
    <InfoText>
      <strong>13.2.</strong>
      {' '}
      If any provision of these Terms and Conditions is or
      becomes invalid, illegal or
      unenforceable in any respect, it shall to the extent of such invalidity, illegality or
      unenforceability be
      deemed to be severed and removed and all remaining terms shall remain in full force and
      effect.
    </InfoText>
    <InfoText>
      <strong>13.3.</strong>
      {' '}
      This Agreement constitutes the entire agreement applicable
      to the NFTs Airdrop
      and supersedes any and all previous written or oral content, agreement, representations
      or undertakings
      between the You and Homa.
    </InfoText>
    <InfoText>
      <strong>13.4. </strong>
      Homa shall not bear any liability for any failure or delay
      in the performance of
      its obligations under this Agreement if such delay or failure to perform is due to any “
      <strong>
        Force
        Majeure
      </strong>
      ”. For the purpose of this Agreement, Force Majeure shall be defined
      as any act,
      omissions, cause or circumstance beyond the reasonable control of Homa, this to include
      without any
      limitations: acts of god, war, national emergency, protests or rebellion, civil
      commotion, riots or strikes,
      earthquake, flood, drought, epidemic, fire, explosion, act of terrorism, or any other
      act ordered by any
      government, council or constituted body.
    </InfoText>
    <InfoText>
      <strong>13.5.</strong>
      {' '}
      Any waiver of any breach of this Agreement by Homa, or any
      default, under any
      provision of the Agreement by the Beneficiary, shall only be valid if agreed in writing.
      Any further or
      subsequent breach or default by the Beneficiary whether similar or otherwise shall in no
      way affect this
      Agreement. Similarly, any failure or delay, by either Party to this Agreement, to insist
      upon strict
      performance of any of the provisions of the Agreement shall not be construed as a waiver
      of any of its rights,
      under this Agreement.
    </InfoText>
    <InfoText>
      <strong>13.6.</strong>
      . It is acknowledged by the Beneficiary that they have not
      acted, relied on, or
      been induced to enter into the claim or mint of NFTs by reason of any representations
      made by or on behalf of
      Homa .
    </InfoText>
    <InfoText>
      <strong>13.7. </strong>
      Homa reserves the right to assign its rights and duties
      under these Terms and
      Conditions to any person at any time without notice to you, nor will we contact you or
      seek your approval on
      any such assignment.
    </InfoText>
    <InfoText>
      <strong>13.8. </strong>
      Unless you tell us otherwise, or the law requires
      otherwise, you agree to receive
      all communications from us by e-mail. You agree that you can print the communications
      for your records. You
      agree that all communications that we send to you electronically satisfy any legal
      requirement that a
      communication be in writing.
    </InfoText>
    <InfoText>
      <strong>13.9</strong>
      {' '}
      The Beneficiary further acknowledges that he has carefully
      read and accepted the
      (i) Website Terms of Service accessible at https://www.homagames.com/terms (the
      “
      <strong>
        Website
        Terms
      </strong>
      ”) and (ii) the Website Privacy Policy accessible at
      https://www.homagames.com/privacy (the
      “
      <strong>Website Privacy Policy</strong>
      ”) governing its use of the Website. In case of
      conflict between this
      Agreement and any provision of the Website Terms or the Website Privacy Policy, this
      Agreement will prevail
      with respect to the NFTs.
    </InfoText>

    <SectionTitle>14. DATA PROTECTION</SectionTitle>

    <InfoText>
      <strong>14.1.</strong>
      {' '}
      You represent and warrant that your disclosure to us of any
      personal data
      relating to individuals other than yourself was or will be made in accordance with all
      applicable data
      protection and data privacy laws, and those data are accurate, up to date and relevant
      when
      disclosed
    </InfoText>
    <InfoText>
      <strong>14.2.</strong>
      {' '}
      You acknowledge that Homa, acting as data controller, will
      collect and process
      your email address only for the purpose of performing these Terms and Conditions, or the
      Access Rights. The
      legal basis of such processing is the performance of the Agreement. We will keep your
      email address for the
      duration of our commercial relationship and up to 5 years after it ended. We will not
      share this information
      with any third party other than our technical service providers on a need to know basis
      for the performance of
      this Agreement.
    </InfoText>
    <InfoText>
      <strong>14.3.</strong>
      {' '}
      You have the right to exercise all personal data rights
      attached to your date and
      you can do so by contacting us at dpo@homagames.com. If you believe, after having
      contacted us, that your
      rights are not respected, you can lodge a complaint with the competent supervisory
      authority, such as the
      French Data Protection Authority (Commission Nationale de l’Informatique et des
      Libertés).
      For more
      information on your rights and/or how Homa is processing your personal data, please
      refer
      to our privacy
      Policy
      {' '}
      <UnderlinedText>
        <a
          href="https://www.homagames.com/privacy"
        >
          https://www.homagames.com/privacy
        </a>
      </UnderlinedText>
      .
    </InfoText>

    <SectionTitle>15. LAW AND JURISDICTION</SectionTitle>
    <InfoText>
      <strong>15.1.</strong>
      {' '}
      These Terms and Conditions shall be governed and construed
      in accordance with the
      laws of France.
    </InfoText>
    <InfoText>
      <strong>15.2.</strong>
      {' '}
      You acknowledge and accept that in the event of a claim or
      dispute, they shall be
      submitted to the exclusive jurisdiction of the Paris Court of Appeal.
    </InfoText>
    <InfoText>
      <strong>15.3.</strong>
      {' '}
      These Terms and Conditions may be translated by Homa or
      third parties into other
      languages. The English version will prevail in case of differences arising in
      translation.
    </InfoText>

    <SectionTitle>CONTACT US</SectionTitle>
    <InfoText>
      If you have any question about these Terms and Conditions, please
      contact:
      <a
        href="mailto:homagang.newsletter@gmail.com."
      >
        <UnderlinedPinkText>homagang.newsletter@gmail.com</UnderlinedPinkText>
      </a>
      .
    </InfoText>

    <SectionTitle><UnderlinedText>Annex</UnderlinedText></SectionTitle>
    <SectionTitle>Risk Factors</SectionTitle>

    <InfoText>
      <UnderlinedText>
        Risk of loss of access to NFTs due to loss of
        credentials
      </UnderlinedText>
    </InfoText>
    <InfoText>
      The loss of credentials (including cryptographic private key) will result in the
      loss of the airdropped NFTs. Any third party that obtains access to the Beneficiary’s
      credentials including cryptographic private keys may be able to use or sell the
      Beneficiary’s NFTs.
    </InfoText>

    <InfoText>
      <UnderlinedText>
        Risks Associated with the Ethereum
        blockchain
      </UnderlinedText>
    </InfoText>
    <InfoText>
      The NFTs are based on the Ethereum blockchain. Therefore, any malfunction, unplanned
      function, or unexpected operation of the Ethereum blockchain may cause the Website and/or
      NFTs to malfunction or operate in a way that is not expected. Homa cannot warranty that
      value or loss of value of ETH which is a risk the Beneficiary
      acknowledges.
    </InfoText>

    <InfoText>
      <UnderlinedText>
        Legal risk and risk of adverse regulatory intervention in one or more
        jurisdictions
      </UnderlinedText>
    </InfoText>
    <InfoText>
      The operation of the Website and the Airdrop of the NFTs may be impacted by the passing of
      restrictive laws, the publication of restrictive or negative opinions, the issuing of
      injunctions by national regulators, the initiation of regulatory actions or investigations,
      including but not limited to restrictions on the use or ownership of intangible assets such as
      NFTs.
    </InfoText>
    <InfoText>
      Given the lack of cryptocurrency qualifications in most countries, each Beneficiary
      acknowledges the risk of legal changes that may affect the NFTs acquired under this contract
      and remains free to carry out legal and tax analysis concerning the claim, mint and ownership
      of NFTs according to the laws applicable to their nationality and place of residence.
    </InfoText>

    <InfoText>
      <UnderlinedText>Risk of an alternative, unofficial ecosystem</UnderlinedText>
    </InfoText>
    <InfoText>
      Following development of the original version of the Website, the official Website may
      find itself in competition with alternatives, unofficial websites, which could potentially
      adversely impact the Website and the NFTs.
    </InfoText>

    <InfoText>
      <UnderlinedText>
        Risk of a lack of interest in the Website, the NFTs or Access
        Rights
      </UnderlinedText>
    </InfoText>
    <InfoText>
      There is a possibility that there may be a limited public interest in the Website, the
      NFTs and/or the Access Rights. Such a possible lack of interest could eventually impact
      the development of the Website and, therefore, the uses or potential value of the NFTs.
    </InfoText>

    <InfoText><UnderlinedText>Risk of theft and piracy</UnderlinedText></InfoText>
    <InfoText>
      Hackers or other malicious or criminal groups or organizations may attempt to interfere
      with the Website or the availability of the NFTs in several ways including, but not limited
      to, denial of service attacks, Sybil attacks, mystification, surfing, malware attacks, or
      consensus-based attacks.
    </InfoText>

    <InfoText>
      <UnderlinedText>
        Risk of security weaknesses in the Website’s core infrastructure
        software
      </UnderlinedText>
    </InfoText>
    <InfoText>
      There is a risk that Homa team, or other third parties, may intentionally or
      unintentionally introduce weaknesses or bugs into the core infrastructure elements of the
      Website, by interfering with the use of, or causing loss of, NFTs.
    </InfoText>

    <InfoText>
      <UnderlinedText>
        Risk of weakness or exploitable breakthrough in the field of
        cryptography
      </UnderlinedText>
    </InfoText>
    <InfoText>
      Advances in cryptography, or technical advances such as the development of quantum
      computers, may present risks for cryptocurrencies, the NFTs and the Website, which could
      result in the theft or loss of NFTs.
    </InfoText>

    <InfoText><UnderlinedText>Liquidity Risk</UnderlinedText></InfoText>
    <InfoText>
      There is no assurance that a secondary market will develop and that the holders will be
      able to sell their NFTs.
    </InfoText>

    <InfoText><UnderlinedText>Unforeseen risks</UnderlinedText></InfoText>
    <InfoText>
      Cryptocurrencies and cryptographic tokens are a new and untested technology. In addition
      to the risks stipulated above, there are other risks that cannot be predicted. Risks may also
      arise out of and/or occur as unanticipated combinations or as changes in the risks stipulated
      herein.
    </InfoText>

    <InfoText>
      <UnderlinedText>
        Nothing provided herein shall constitute legal advice delivered by Homa to
        You.
      </UnderlinedText>
    </InfoText>
    <InfoText>
      If you are in any doubt as to the action you should take and the consequences it will
      involve, please consider consulting your own legal, financial, tax or other professional
      advisor(s) for necessary advice.
    </InfoText>

    <DividerImg src={HomaDivider} alt="Divider between sections" />
  </SectionWrapper>
);
