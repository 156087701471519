import {
  AnswerContainer,
  AnswerText,
  IndividualFaqQuestionContainer,
  QuestionContainer,
  QuestionText,
} from './styles';

export const IndividualFaqQuestion = ({ question, answer, isLast }:
{ question: string; answer: string; isLast: boolean;
}) => (
  <IndividualFaqQuestionContainer isLast={isLast}>
    <QuestionContainer>
      <QuestionText>{question}</QuestionText>
    </QuestionContainer>
    <AnswerContainer>
      <AnswerText>{answer}</AnswerText>
    </AnswerContainer>
  </IndividualFaqQuestionContainer>
);
