import {
  AgoraOverlay, BridgeOverlay, FunfairOverlay, GangOverlay, RedactedOverlay,
} from '@assets';

export enum ROADMAP_PARTS {
    Gang = 'Gang',
    Funfair = 'Funfair',
    Redacted = 'Redacted',
    Bridge = 'Bridge',
    Agora = 'Agora',
}

export const OverlayMap: Record<ROADMAP_PARTS, {
    overlaySrc: string,
    description: string,
    gradientColor: string,
}> = {
  [ROADMAP_PARTS.Gang]: {
    overlaySrc: GangOverlay,
    description: 'First came the Gang. Valentine is the first one but she won’t be the last, a whole team is forming. They will become the first decentralized set of characters available to every game creator in the world. Stay tuned, their story is coming.',
    gradientColor: '#F8D3F1',
  },
  [ROADMAP_PARTS.Agora]: {
    overlaySrc: AgoraOverlay,
    description: 'The Agora is a social area to make encounters, exchange ideas and participate to events. A council of respected and valued primus inter pares emerged. It was named the High Council.',
    gradientColor: '#F08C59',
  },
  [ROADMAP_PARTS.Funfair]: {
    overlaySrc: FunfairOverlay,
    description: 'The Funfair is a never-ending entertainment experience. Get immersed in a unique world of interconnected games and bring the experience to a whole new level. At the center of it lies the narrative-driven game Homa Tales, deep-diving on a turning point in the history of the Gang.\n',
    gradientColor: '#FF87BF',
  },
  [ROADMAP_PARTS.Bridge]: {
    overlaySrc: BridgeOverlay,
    description: 'At the boundaries of the Homa World lied the Bridge. A mysterious area, it is rumored to be a gateway between the Homa World and the real World. Don’t venture too close to the Rift though, no one has ever come back from it…',
    gradientColor: '#AFD0F2',
  },
  [ROADMAP_PARTS.Redacted]: {
    overlaySrc: RedactedOverlay,
    description: 'No one really knew what is developing there but it has been very active recently...',
    gradientColor: '#C786D8',
  },
};
