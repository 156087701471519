import { Box, styled } from '@mui/material';
import { LightSphereIcon } from '@global/assets';
import { pinkToBlue } from '@global/colors';

export const SectionWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  background: 'linear-gradient(to right, rgba(15, 15, 15, 1), rgba(47, 33, 47, 1))',
  paddingLeft: '10vw',
  paddingRight: '10vw',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const Grid = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'grid',
  gridTemplateRows: 'repeat(3, 1fr)',
  gridTemplateColumns: 'repeat(3, 1fr)',
  width: '100%',
}));

export const Cell = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderImage: pinkToBlue(0.4),
  borderImageSlice: 1,

  '&:nth-of-type(-n+6)': {
    borderBottom: '1px solid',
  },

  '&:not(:nth-of-type(3n))': {
    borderRight: '1px solid pink',
  },

  '&:nth-of-type(-n+5):not(:nth-of-type(3n))': {
    '&:after': {
      content: `url(${LightSphereIcon})`,
      position: 'absolute',
      bottom: theme.spacing(-4.5),
      right: theme.spacing(-4.2),
      width: theme.spacing(8),
      height: theme.spacing(8),
      transform: 'scale(0.75)',
    },
  },
}));
