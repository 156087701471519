import { InfoHeading } from '@global/styles';
import {
  SectionWrap, Banner, InfoContainer, StyledInfoText,
} from './styles';

export const HomaPeopleSection = () => (
  <SectionWrap>
    <Banner />
    <InfoContainer>
      <InfoHeading>
        OVER 160+ PEOPLE
      </InfoHeading>
      <StyledInfoText>
        are working at Homa, building entertainment for you
      </StyledInfoText>
    </InfoContainer>
  </SectionWrap>
);
