export const FAQs = [
  {
    question: 'When was the mint?',
    answer: 'The mint took place on our website on Thursday 17th November at 08:00 CET.',
  },
  {
    question: 'How many Valentine NFTs is there?',
    answer: 'There are 7000 first generation Valentine NFT.',
  },
  {
    question: 'Was there a Whitelist?',
    answer: 'Yes, we have been rewarding the first members of our community with whitelist spots. They had a guaranteed opportunity to mint a Valentine NFT.',
  },
  {
    question: 'What was the mint price?',
    answer: 'The mint was totally free! You only needed to pay the transaction gas fee to obtain your Valentine.',
  },
  {
    question: 'How can I discover my Valentine?',
    answer: 'Click on the "Meet Your Valentine" button on top of the website page and follow the steps.',
  },
  {
    question: 'What does my NFT grant?',
    answer: 'Owning a Valentine NFT will grant you a privileged access to our universe. We will provide multiple experiences for NFT holders and games will be at the center.',
  },
  {
    question: 'What are the royalty fees on secondary transactions?',
    answer: 'HOMA retains 10% royalties on secondary market sales to continue fueling the Homa Gang.',
  },
  {
    question: 'How can I join the team?',
    answer: 'Join our Discord to interact directly with our community and team members.',
  },
];
