import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { PageSectionID } from '@global/consts';
import {
  RoadMap,
  FunfairPart,
  GangPart,
  RedactedPart,
  BridgePart,
  AgoraPart,
  PinPoint,
} from '@global/assets';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import {
  RoadmapImageContainer,
  RoadmapImage,
  MapSectionWrapper,
  MapPartContainer,
  PinPointImage,
  AreaWithPointerCursor,
  SectionSubHeader,
  HeaderContainer,
} from './styles';
import { TextH1 } from '../homa-intro/styles';
import { ROADMAP_PARTS } from './consts';
import { RoadmapOverlay } from './roadmap-overlay';

export const HomaRoadmap = () => {
  const [gangCoords, setGangCoords] = useState('');
  const [funfairCoords, setFunfairCoords] = useState('');
  const [agoraCoords, setAgoraCoords] = useState('');
  const [bridgeCoords, setBridgeCoords] = useState('');
  const [redactedCoords, setRedactedCoords] = useState('');

  const [hoveringOver, setHoveringOver] = useState<ROADMAP_PARTS | ''>('');

  const [mapState, setMapState] = useState<'init' | 'tuto' | ROADMAP_PARTS | null>('init');

  const imgContainerRef = useRef<HTMLImageElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const openSection = (titleToShow: ROADMAP_PARTS) => {
    setMapState(titleToShow);
  };

  const getPixelCoordsFromPercentages = (percentageCoords: string) => {
    const imageHeight = imgRef.current?.height;
    const imageWidth = imgRef.current?.width;

    if (!imageHeight || !imageWidth) return '';

    const percentages = percentageCoords.split(',');
    const pixels = percentages.map((percentage, i) => {
      const dimensionToUse = i % 2 === 0 ? imageWidth : imageHeight;
      const percentageValue = Number(percentage.substring(0, percentage.length - 1));
      return (percentageValue * dimensionToUse) / 100;
    });

    return pixels.join(',');
  };

  const calculateAndSetMapPixels = useCallback(() => {
    setGangCoords(getPixelCoordsFromPercentages('0,0,47%,23%'));
    setFunfairCoords(getPixelCoordsFromPercentages('25%,49%,50%,25%,78%,49%,50%,81%'));
    setRedactedCoords(getPixelCoordsFromPercentages('40%,100%,100%,100%,100%,43%'));
    setAgoraCoords(getPixelCoordsFromPercentages('77%,3%,59%,23%,80%,39%,99%,20%'));
    setBridgeCoords(getPixelCoordsFromPercentages('48%,0%,74%,0%,53%,23%'));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculateAndSetMapPixels, false);

    return () => window.removeEventListener('resize', calculateAndSetMapPixels);
  }, [calculateAndSetMapPixels]);

  const getMouseEventsForMapArea = (partName: ROADMAP_PARTS) => ({
    onMouseEnter: () => setHoveringOver(partName),
    onMouseLeave: () => setHoveringOver(''),
  });

  const roadmapImageWidth = imgRef.current?.width;

  // Hack to set the roadmap on funfair on mobile by default (When the map is interactive)
  useEffect(() => {
    if (
      !isDesktop
      && roadmapImageWidth
      && imgContainerRef.current?.scrollLeft === 0
      && mapState === null
    ) {
      imgContainerRef.current.scrollLeft += imgContainerRef.current.scrollWidth / 2.91;
    }
  }, [mapState, isDesktop, roadmapImageWidth]);

  // Hack to set the roadmap on funfair on mobile by default (When the map isn't interactive yet)
  const imageLeftOffset = !isDesktop && (mapState === 'init' || mapState === 'tuto') ? (roadmapImageWidth ?? 0) / 2.91 : 0;

  return (
    <MapSectionWrapper id={PageSectionID.Roadmap}>
      <HeaderContainer>
        <TextH1>Our Roadmap</TextH1>
        <SectionSubHeader>
          Explore the 4 building blocks of the homa world: Funfair, Bridge, Agora, and The Gang.
        </SectionSubHeader>
      </HeaderContainer>
      <RoadmapImageContainer isMobileReady={!mapState} ref={imgContainerRef}>
        <PinPointImage
          src={PinPoint}
          onClick={() => setMapState(ROADMAP_PARTS.Gang)}
          mapState={mapState}
          left={(roadmapImageWidth || 0) / 3.1}
          top={10}
        />
        <PinPointImage
          src={PinPoint}
          onClick={() => setMapState(ROADMAP_PARTS.Agora)}
          mapState={mapState}
          left={(roadmapImageWidth || 0) / 1.31}
          top={20}
        />
        <PinPointImage
          src={PinPoint}
          onClick={() => setMapState(ROADMAP_PARTS.Funfair)}
          mapState={mapState}
          left={(roadmapImageWidth || 0) / 2.125}
          top={60}
        />
        <PinPointImage
          src={PinPoint}
          onClick={() => setMapState(ROADMAP_PARTS.Bridge)}
          mapState={mapState}
          left={(roadmapImageWidth || 0) / 1.7}
          top={3}
        />
        <PinPointImage
          src={PinPoint}
          onClick={() => setMapState(ROADMAP_PARTS.Redacted)}
          mapState={mapState}
          left={(roadmapImageWidth || 0) / 1.31}
          top={50}
        />
        <RoadmapImage
          src={RoadMap}
          ref={imgRef}
          alt="Homa Roadmap"
          useMap="#roadmap"
          onLoad={calculateAndSetMapPixels}
          isDimmed={!!hoveringOver}
          leftOffset={imageLeftOffset}
        />
        {isDesktop ? (
          <Dialog
            open={!!mapState && mapState !== 'init' && mapState !== 'tuto'}
            fullScreen
            fullWidth
            PaperProps={{
              style: {
                backgroundImage: `url(${RoadMap})`,
              },
            }}
          >
            <RoadmapOverlay mapState={mapState} setMapState={setMapState} />
          </Dialog>
        )
          : <RoadmapOverlay mapState={mapState} setMapState={setMapState} />}
        {isDesktop && (
        <map name="roadmap">
          <AreaWithPointerCursor
            {...getMouseEventsForMapArea(ROADMAP_PARTS.Gang)}
            shape="rect"
            coords={gangCoords}
            alt="Gang"
            onClick={() => openSection(ROADMAP_PARTS.Gang)}
          />
          <AreaWithPointerCursor
            {...getMouseEventsForMapArea(ROADMAP_PARTS.Agora)}
            shape="poly"
            coords={agoraCoords}
            alt="Agora"
            onClick={() => openSection(ROADMAP_PARTS.Agora)}
          />
          <AreaWithPointerCursor
            {...getMouseEventsForMapArea(ROADMAP_PARTS.Bridge)}
            shape="poly"
            coords={bridgeCoords}
            alt="Bridge"
            onClick={() => openSection(ROADMAP_PARTS.Bridge)}
          />
          <AreaWithPointerCursor
            {...getMouseEventsForMapArea(ROADMAP_PARTS.Funfair)}
            shape="poly"
            coords={funfairCoords}
            alt="Funfair"
            onClick={() => openSection(ROADMAP_PARTS.Funfair)}
          />
          <AreaWithPointerCursor
            {...getMouseEventsForMapArea(ROADMAP_PARTS.Redacted)}
            shape="poly"
            coords={redactedCoords}
            alt="Redacted"
            onClick={() => openSection(ROADMAP_PARTS.Redacted)}
          />
        </map>
        )}
        <MapPartContainer src={GangPart} alt="Homa Funfair Part" isLit={hoveringOver === 'Gang'} />
        <MapPartContainer src={FunfairPart} alt="Homa Funfair Part" isLit={hoveringOver === 'Funfair'} />
        <MapPartContainer src={RedactedPart} alt="Homa Redact Part" isLit={hoveringOver === 'Redacted'} />
        <MapPartContainer src={BridgePart} alt="Homa Redact Part" isLit={hoveringOver === 'Bridge'} />
        <MapPartContainer src={AgoraPart} alt="Homa Redact Part" isLit={hoveringOver === 'Agora'} />
      </RoadmapImageContainer>
    </MapSectionWrapper>
  );
};
