import { styled, Typography } from '@mui/material';
import { Colors } from './colors';

export const InfoHeading = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  textTransform: 'uppercase',
  fontSize: theme.typography.pxToRem(48),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: '0px 0px 30px rgb(255 145 250 / 40%)',

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(36),
  },
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  color: Colors.FadedGray,
  fontSize: theme.typography.pxToRem(18),
}));
