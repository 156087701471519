import { Colors } from '@global/colors';
import { Box, styled, Typography } from '@mui/material';
import ValentineWheelBGDesktop from '@global/assets/backgrounds/valentine-ferry-wheel-desktop.jpeg';
import { InfoHeading } from '@global/styles';

export const SectionWrapper = styled(Box)({
  width: '100%',
  padding: '2.5% 10% 5%',
  paddingTop: '15vh',
  background: 'linear-gradient(to right, rgba(15, 15, 15, 1), rgba(47, 33, 47, 1))',
});

export const ManifestoText = styled(Typography)(({ theme }) => ({
  color: Colors.SassyPink,
  fontSize: theme.typography.pxToRem(20),
  marginBottom: theme.spacing(2),
}));

export const StyledInfoHeading = styled(InfoHeading)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(2),
}));

export const BackgroundDivider = styled(Typography)({
  width: '100%',
  height: '70vh',
  backgroundImage: `url(${ValentineWheelBGDesktop})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

export const DividerImg = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: '100%',
}));
