import { Box, styled } from '@mui/material';
import { Colors } from '@global/colors';

export const SectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: 'linear-gradient(to right, rgba(15, 15, 15, 1), rgba(47, 33, 47, 1))',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));

export const SectionPartContainer = styled(Box)(({ theme }) => ({
  width: '80vw',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(10),
  color: Colors.White,
}));

export const OurGamesSection = styled(SectionPartContainer)({
  background: `radial-gradient(${Colors.SassyPink}30 10%, #fff0 50%)`,
});

