import {
  alpha, Box, styled, Typography,
} from '@mui/material';
import ValentineSkylineBGMobile from '@asset/backgrounds/valentine-skyline-mobile.jpeg';
import ValentineSkylineBGDesktop from '@asset/backgrounds/valentine-skyline-desktop.jpeg';
import { Colors } from '@global/colors';
import { textGlow } from '@global/animations';

export const SectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: `url(${ValentineSkylineBGDesktop})`,
  backgroundSize: 'cover',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${ValentineSkylineBGMobile})`,
  },
}));

export const HeaderTextWrap = styled(Box)({
  color: Colors.White,
  textAlign: 'center',
});

export const RevealNowText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
  textAlign: 'center',
  color: Colors.Black,
  textTransform: 'uppercase',
  fontSize: theme.typography.pxToRem(24),
  fontWeight: theme.typography.fontWeightBold,
  animation: `${textGlow(Colors.SassyPink)} 1.25s ease-in-out infinite alternate`,
  textShadow: `0px 0px 24px ${alpha(Colors.SassyPink, 0.6)}`,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

export const TextH1 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(104),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: `0px 12px 20px ${alpha(Colors.CoolDarkBlue, 0.8)}`,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(44),
  },
}));

export const TextH2 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: theme.typography.fontWeightRegular,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(20),
  },
}));
