import {
  SectionWrapper, TextH1, TextH2, HeaderTextWrap,
} from './styles';

export const HeroSection = () => (
  <SectionWrapper>
    <HeaderTextWrap>
      <TextH1 variant="h1">YOU ARE THE HERO</TextH1>
      <TextH2 variant="h2">
        Collect assets, mold them to your dreams and carry them everywhere you play.
        Own the game.
      </TextH2>
    </HeaderTextWrap>
  </SectionWrapper>
);
