import {
  alpha, Box, styled, Typography,
} from '@mui/material';
import { Colors } from '@global/colors';
import { transientProps } from '@global/utils';

export const TextH1 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: `0px 0px 30px ${alpha(Colors.SassyPink, 0.4)}`,
  marginBottom: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(28),
  },
}));

export const IndividualFaqQuestionContainer = styled(Box, transientProps('isLast'))<{ isLast?: boolean }>(({ theme, isLast }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(5),
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },

  ...(isLast ? {} : {
    borderBottom: '0.5px solid',
    borderImageSource: 'linear-gradient(to right, rgba(255, 145, 250, 0.7), rgba(228, 247, 255, 0.7));',
    borderImageSlice: 1,
    borderRadius: 0,
  }),
}));

export const QuestionContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flex: '1 1 0',
    marginRight: theme.spacing(3),
  },
}));

export const AnswerContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flex: '2 2 0',
    marginLeft: theme.spacing(3),
  },
}));

export const QuestionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(22),
  fontWeight: 400,
}));

export const AnswerText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: 400,
  color: '#cccccc',
}));
