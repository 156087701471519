import {
  alpha, Box, styled, Typography,
} from '@mui/material';
import { Button } from '@components';
import { Colors } from '@global/colors';
import { leftToRight } from '@global/animations';
import { transientProps } from '@global/utils';
import { SectionWrapper } from '../../styles';

export const TextH1 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: `0px 0px 30px ${alpha(Colors.SassyPink, 0.4)}`,
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(28),
  },
}));

export const SlidingTextH1 = styled(TextH1, transientProps('isanimating'))<{ isanimating?: boolean }>(({ theme, isanimating }) => ({
  transform: isanimating ? '' : 'translateX(-100%)',
  animation: isanimating ? `${leftToRight} 1s ease` : '',
  marginBottom: theme.spacing(1.5),
}));

export const PinkTextH1 = styled('span')({
  color: Colors.SassyPink,
});

export const TextDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  textShadow: `0px 12px 20px ${alpha(Colors.CoolDarkBlue, 0.8)}`,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export const IntroTextDescription = styled(TextDescription)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  opacity: '80%',
  marginBottom: theme.spacing(6),
}));

export const SlidingTextDescription = styled(TextDescription, transientProps('isanimating'))<{ isanimating?: boolean }>(({ isanimating }) => ({
  transform: isanimating ? '' : 'translateX(-100%)',
  animation: isanimating ? `${leftToRight} 2s ease` : '',
}));

export const PinkTextDescription = styled('span')(({ theme }) => ({
  color: Colors.SassyPink,
  marginBottom: theme.spacing(0.5),
}));

export const CheckCollectionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: 'fit-content',
  alignSelf: 'center',
}));

export const Descriptor = styled('p')(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: '20px',
  color: 'rgba(255, 255, 255, 0.6)',
  textAlign: 'center',
}));

export const AllGameFactsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  height: '25%',
}));

export const GameFactContainer = styled(Box)(({ theme }) => ({
  maxWidth: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const DigitalValue = styled('p')(({ theme }) => ({
  background: `linear-gradient(91.71deg, ${Colors.SassyPink} 9.24%, #C4DFFE 103.23%)`,
  backgroundClip: 'text',
  fontSize: theme.typography.pxToRem(80),
  textFillColor: 'transparent',
  fontWeight: theme.typography.fontWeightExtraBold,
  margin: 0,
}));

export const GameCardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  paddingLeft: theme.spacing(1),
  gap: theme.spacing(1),
}));

export const Unit = styled(Descriptor)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '25px',
  color: Colors.White,
}));

export const GameName = styled(Unit)(({ theme }) => ({
  textAlign: 'left',
  marginBottom: theme.spacing(1),
}));

export const ValentineIntroContainer = styled(Box)(({ theme }) => ({
  width: '80vw',
  display: 'flex',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));

export const ValentineIntroItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(3),
  flexBasis: '100%',
  [theme.breakpoints.up('md')]: {
    flexBasis: '50%',
  },
}));

export const RandomValentineContainer = styled(Box)({
  flexBasis: '40%',
});

export const ValentineIntroTextContainer = styled(ValentineIntroItem)(({ theme }) => ({
  color: Colors.White,
  gap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const StatsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const GamesHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));

export const DiscoverMoreText = styled(PinkTextDescription)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
  },
}));

export const GameCardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));

export const IndividualStatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const GameCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(0, 0, 0, 0.24)',
  borderRadius: '12px',
  width: 'fit-content',
  padding: theme.spacing(4, 2),
  gap: theme.spacing(2.5),
}));

export const HomaDividerImage = styled('img')({
  width: '75vw',
});

export const GameLogoImage = styled('img')(({ theme }) => ({
  width: theme.spacing(15),
}));

export const HomaWorkSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  paddingBottom: theme.spacing(20),
}));
