import { Colors, pinkToBlue } from '@global/colors';
import { styled, Button, Typography } from '@mui/material';
import { transientProps } from '@global/utils';
import { HoverOverlay } from '../hover-overlay/hover-overlay';

export const StyledButton = styled(Button, transientProps('boldText'))<{ boldText?: boolean }>(({ theme, size, boldText }) => ({
  borderRadius: 0,
  fontWeight: 'bold',
  background: pinkToBlue(0.15),
  border: '1px solid',
  borderImage: pinkToBlue(),
  borderImageSlice: 1,
  height: size === 'large' ? '55px' : '45px',
  padding: theme.spacing(1, 4),
  transition: 'background 1s ease-in',

  '&:disabled': {
    opacity: '0.5 !important',
    cursor: 'not-allowed  !important',
    pointerEvents: 'all !important',
  },

  '.MuiTypography-root': {
    fontSize: theme.typography.pxToRem(size === 'large' ? 20 : 16),
    fontWeight: boldText
      ? theme.typography.fontWeightExtraBold
      : theme.typography.fontWeightBold,
  },

  [theme.breakpoints.down('md')]: {
    height: size === 'large' ? '50px' : '40px',

    '.MuiTypography-root': {
      fontSize: theme.typography.pxToRem(size === 'large' ? 16 : 12),
    },
  },
}));

export const StyledButtonHoverOverlay = styled(HoverOverlay)({
  border: '1px solid',
  borderImageSource: `linear-gradient(90deg, ${Colors.SassyPink} 0%, ${Colors.SkyBlue} 100%)`,
  borderImageSlice: 1,
  borderRadius: 0,
});

export const GradientText = styled(Typography)<{ color?: string }>(({ color }) => ({
  background: color ?? pinkToBlue(),
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));
