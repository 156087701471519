import { PageSectionID } from '@global/consts';
import { TextH1 } from './styles';
import { SectionWrapper, SectionPartContainer } from '../../styles';
import { IndividualFaqQuestion } from './individual-faq-question';
import { FAQs } from './consts';

export const FaqSection = () => (
  <SectionWrapper id={PageSectionID.FAQ}>
    <SectionPartContainer>
      <TextH1>FAQ</TextH1>
      {FAQs.map((oneFAQ, i) => (
        <IndividualFaqQuestion {...oneFAQ} isLast={i === FAQs.length - 1} key={oneFAQ.question} />
      ))}
    </SectionPartContainer>
  </SectionWrapper>
);
