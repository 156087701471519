import {
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Colors } from '@global/colors';
import {
  HomaDivider,
  RandomValentine1,
  RandomValentine2,
  RandomValentine3,
  RandomValentine4,
} from '@global/assets';
import { PageSectionID } from '@global/consts';
import {
  PinkTextH1,
  SlidingTextH1,
  SlidingTextDescription,
  ValentineIntroContainer,
  ValentineIntroItem,
  ValentineIntroTextContainer,
  HomaDividerImage,
  RandomValentineContainer,
  CheckCollectionButton,
} from './styles';
import { SectionWrapper } from '../../styles';

export const HomaIntro = () => {
  const myRef = useRef<HTMLDivElement>();
  const [isIntroTextContainerVisible, setIsIntroTextContainerVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setIsIntroTextContainerVisible(entry.isIntersecting);
    });
    observer.observe(myRef.current as HTMLDivElement);
  }, []);

  return (
    <SectionWrapper id={PageSectionID.Who}>
      <ValentineIntroContainer>
        <ValentineIntroTextContainer ref={myRef as RefObject<HTMLDivElement>}>
          <SlidingTextH1 isanimating={isIntroTextContainerVisible}>
            DISCOVER
            <PinkTextH1> 7000 </PinkTextH1>
            UNIQUE VARIATIONS OF VALENTINE
          </SlidingTextH1>
          <SlidingTextDescription isanimating={isIntroTextContainerVisible}>
            Owning a Valentine will grant you benefits in our universe.
          </SlidingTextDescription>
          <CheckCollectionButton
            textColor={Colors.White}
            onClick={() => window.open('https://opensea.io/collection/homa-gang-valentine')}
          >
            Check Collection
          </CheckCollectionButton>
        </ValentineIntroTextContainer>
        <ValentineIntroItem>
          <RandomValentineContainer>
            <img src={RandomValentine1} alt="Valentine quadrant item 1" />
          </RandomValentineContainer>
          <RandomValentineContainer>
            <img src={RandomValentine2} alt="Valentine quadrant item 2" />
          </RandomValentineContainer>
          <RandomValentineContainer>
            <img src={RandomValentine3} alt="Valentine quadrant item 3" />
          </RandomValentineContainer>
          <RandomValentineContainer>
            <img src={RandomValentine4} alt="Valentine quadrant item 4" />
          </RandomValentineContainer>
        </ValentineIntroItem>
      </ValentineIntroContainer>
      <HomaDividerImage src={HomaDivider} alt="divider" />
    </SectionWrapper>
  );
};
