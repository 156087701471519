import {
  Box, IconButton, styled, Typography,
} from '@mui/material';
import { TextField, Button } from '@components';
import { Colors, fadedWhite03, fadedWhite08 } from '../../colors';
import { HomaGamesLogo } from '../homa-games-logo/homa-games-logo';
import { Socials } from '../socials/socials';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '../../consts';

export const MainContentWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(5, 15, 1),

  [theme.breakpoints.down('lg')]: {
    alignItems: 'space-between',
  },
}));

export const LogoAndSocialsWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const NewsletterForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledHomaGamesLogo = styled(HomaGamesLogo)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '144px',
    maxHeight: '70px',
  },
}));

export const StyledWrap = styled('footer')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.spacing(4),
  background: Colors.CoolDarkBlue,
}));

export const PrivacyPolicyAndTermsWrap = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  opacity: 0.5,

  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(4),
  },
}));

export const CopyrightText = styled(Typography)(({ theme }) => ({
  color: fadedWhite03,
  width: '100%',
  textAlign: 'center',
  paddingTop: theme.spacing(4),
}));

export const SocialsWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridGap: theme.spacing(2.5),
}));

export const StyledSocialButton = styled(IconButton)({
  position: 'relative',
  border: `1px solid ${fadedWhite03}`,
  borderRadius: 0,

  transition: `border-color ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms ease-in`,

  '&:hover': {
    borderColor: fadedWhite08,
  },
});

export const InfoTextMain = styled(Typography)(({ theme }) => ({
  color: Colors.SassyPink,
  fontSize: theme.typography.pxToRem(24),
  fontWeight: theme.typography.fontWeightMedium,
}));

export const InfoTextSecondary = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  lineHeight: theme.typography.pxToRem(40),
}));

export const StyledSignUpButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  height: '100%',

  '.MuiTypography-root': {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const SignUpWrap = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
  gridGap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr',
  },
}));

export const StyledTextField = styled(TextField)({
  width: '100%',
});

export const StyledSocials = styled(Socials)({
  display: 'grid',
});

export const SocialsNonMobile = styled(Socials)(({ theme }) => ({
  marginTop: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const SocialsWrapMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const hideInMobile = { display: { xs: 'none', sm: 'none' } };
