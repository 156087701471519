import React, { useState } from 'react';
import { ButtonProps } from './types';
import { StyledButton, StyledButtonHoverOverlay, GradientText } from './styles';

export const Button: React.ForwardRefExoticComponent<ButtonProps> = React.forwardRef(
  ({ children, textColor, ...otherProps }, ref) => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsOverlayVisible(e.type === 'mouseover');
    };

    return (
      <StyledButton
        ref={ref}
        {...otherProps}
        onMouseOut={handleMouseOver}
        onMouseOver={handleMouseOver}
      >
        <GradientText color={textColor}>
          {children}
        </GradientText>
        <StyledButtonHoverOverlay display={isOverlayVisible} />
      </StyledButton>
    );
  },
);
