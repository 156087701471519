import { Box } from '@mui/material';
import { LogoFooter, LogoHeader } from '../../assets';
import { HomaGamesLogoProps } from './types';

export const HomaGamesLogo: React.FC<HomaGamesLogoProps> = ({ header, ...wrapperProps }) => (
  <Box {...wrapperProps}>
    {
      header
        ? <img src={LogoHeader} alt="Homa Games header logo" width="100%" />
        : <img src={LogoFooter} alt="Homa Games footer logo" width="100%" />
    }
  </Box>
);
