import {
  Descriptor,
  DigitalValue,
  IndividualStatContainer,
  Unit,
} from './styles';

export const IndividualStat = ({
  descriptor,
  digitalValue,
  unit,
}: { descriptor: string; digitalValue: string; unit: string; }) => (
  <IndividualStatContainer>
    <Descriptor>{descriptor}</Descriptor>
    <DigitalValue>{digitalValue}</DigitalValue>
    <Unit>{unit}</Unit>
  </IndividualStatContainer>
);
