import React from 'react';
import { Socials } from '../socials/socials';
import { SocialId } from '../socials/types';
import { SocialsWrapMobile } from './styles';

export const SocialsMobile = () => (
  <SocialsWrapMobile sx={{ mt: 4 }}>
    <Socials
      sx={{ mt: 3 }}
      showOnly={
        [SocialId.Twitter, SocialId.Discord, SocialId.OpenSea, SocialId.Website]
      }
    />
    <Socials
      sx={{ mt: 2 }}
      showOnly={
        [SocialId.Instagram, SocialId.TikTok, SocialId.Medium]
      }
    />
  </SocialsWrapMobile>
);
