import { Colors, pinkToBlue } from '@global/colors';
import { Box, styled, TextField } from '@mui/material';
import { transientProps } from '@global/utils';
import { TextFieldProps } from './types';

export const StyledTextField = styled(TextField, transientProps('borderWidth', 'borderRadius', 'borderGradientDirection'))<TextFieldProps>(({
  borderWidth, borderRadius, borderGradientDirection, error,
}) => ({
  '&:before': {
    content: "' '",
    position: 'absolute',
    inset: 0,
    borderRadius: borderRadius || 0,
    padding: `${borderWidth || 1}px`,
    background: error ? Colors.TomatoRed : pinkToBlue(1, borderGradientDirection || 'right'),
    WebkitMask: `
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0)`,
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    pointerEvents: 'none',
  },

  input: {
    color: Colors.White,
  },

  fieldset: {
    display: 'none',
  },

  '.MuiFormHelperText-root': {
    position: 'absolute',
    top: '100%',
  },
}));

export const StyledWrap = styled(Box)({
  position: 'relative',
});
