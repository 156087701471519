import { HEADER_MAX_HEIGHT_MOBILE_PIXELS } from '@global/components/header/consts';
import { transientProps } from '@global/utils';
import { Colors } from '@global/colors';
import {
  alpha,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { swipe } from '@global/animations';
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';
import { SectionWrapper } from '../../styles';

export const MapSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  color: Colors.White,
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(0),
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
    minHeight: theme.spacing(0),
  },
}));

export const RoadmapImageContainer = styled('div', transientProps('isMobileReady'))<{ isMobileReady: boolean }>(({ isMobileReady, theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  overflowX: isMobileReady ? 'auto' : 'initial',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const RoadmapImage = styled('img', transientProps('isDimmed', 'leftOffset'))<{ isDimmed?: boolean, leftOffset: number }>(({ theme, isDimmed, leftOffset }) => ({
  position: 'relative',
  top: 0,
  left: `-${leftOffset}px`,
  opacity: isDimmed ? '50%' : '100%',
  transition: `opacity ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 1.5}ms ease-in-out`,
  zIndex: 69,
  [theme.breakpoints.down('md')]: {
    height: `calc(100vh - ${HEADER_MAX_HEIGHT_MOBILE_PIXELS}px)`,
    maxWidth: 'max-content',
  },
}));

export const MobileStepImage = styled('img')(({ theme }) => ({
  maxWidth: 'none',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    width: '60%',
    flex: 3,
    objectFit: 'contain',
    objectPosition: 'left bottom',
  },
  [theme.breakpoints.down('md')]: {
    width: '110vw',
  },
}));

export const MobileImageOverlay = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    height: '100%',
  },
  [theme.breakpoints.down('md')]: {
    backgroundColor: '#0009',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    height: `calc(100vh - ${HEADER_MAX_HEIGHT_MOBILE_PIXELS}px)`,
    minWidth: '100vw',
    color: 'white',
  },
}));

export const MapSubStoryContainer = styled(Box, transientProps('gradientColor'))<{ gradientColor: string }>(({ gradientColor, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  maxWidth: '100vw',
  background: `linear-gradient(to top, ${gradientColor}, #000B)`,
}));

export const MapPartContainer = styled('img', transientProps('isLit'))<{ isLit: boolean }>(({ isLit, theme }) => ({
  position: 'absolute',
  top: '0%',
  left: '0%',
  cursor: 'pointer',
  transition: `opacity ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 1.5}ms ease-in-out`,
  opacity: isLit ? '100%' : '0%',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const AreaWithPointerCursor = styled('area')({
  cursor: 'pointer',
});

export const ImageOverlayContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const TextH1 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: `0px 0px 30px ${alpha(Colors.SassyPink, 0.4)}`,
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(28),
  },
}));

export const TextDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: theme.typography.pxToRem(18),
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(12),
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
}));

export const RoadmapStepTextDescription = styled(TextDescription)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flex: 2,
  },
}));

export const HeaderContainer = styled(Typography)(({ theme }) => ({
  width: '100%',
  marginLeft: theme.spacing(10),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const SectionSubHeader = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  textShadow: `0px 12px 20px ${alpha(Colors.CoolDarkBlue, 0.8)}`,
  color: Colors.SassyPink,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export const RoadmapStepTextAndImageDescriptionContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '80%',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
  },
}));

export const PinkTextDescription = styled(TextDescription)({
  color: Colors.SassyPink,
});

export const PinkCloseButtonLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  marginLeft: theme.spacing(1),
  color: Colors.SassyPink,
}));

export const SwipeGesture = styled('img')({
  height: '80px',
  animation: `2s ${swipe} 0.25s`,
});

export const CloseIconMobile = styled(CloseIcon)({
  fill: Colors.SassyPink,
  height: '40px',
});

export const PinPointImage = styled('img', transientProps(
  ['left', 'top', 'mapState'],
))<{
  left: number,
  top: number,
  mapState: string | null,
}>(({
  left,
  top,
  mapState,
  theme,
}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  position: 'absolute',
  height: '80px',
  zIndex: 99,
  left: `${left}px`,
  top: `${top}%`,
  display: mapState ? 'none' : 'initial',
}));
