import { useState } from 'react';
import { HoverOverlay } from '../hover-overlay/hover-overlay';
import { SocialsProps } from './types';
import { filterSocials } from './helpers/filter-socials';
import { SocialsWrap, StyledSocialButton } from './styles';

export const Socials = (props: SocialsProps) => {
  const { showOnly, ...wrapperProps } = props;

  const [hoveredOver, setHoveredOver] = useState('opensea');

  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setHoveredOver((e.type === 'mouseover' && e.currentTarget.id) || '');
  };

  const handleClick = (url: string) => {
    window.open(url, '_newtab');
  };

  return (
    <SocialsWrap {...wrapperProps}>
      {
        filterSocials(showOnly).map((social) => social !== undefined
          && (
            <StyledSocialButton
              key={social.id}
              id={social.id}
              onMouseOut={handleMouseOver}
              onMouseOver={handleMouseOver}
              onClick={() => handleClick(social.url)}
            >
              <img src={social.icon} alt={social.alt} />
              <HoverOverlay display={hoveredOver === social.id} />
            </StyledSocialButton>
          ))
      }
    </SocialsWrap>
  );
};
