import { InfoHeading } from '@global/styles';
import { partners } from './consts';
import {
  SectionWrap, Grid, Cell,
} from './styles';

export const PartnersSection = () => (
  <SectionWrap>
    <InfoHeading>
      OUR PARTNERS & INVESTORS
    </InfoHeading>
    <Grid>
      {partners.map((partner) => (
        <Cell key={partner.name}>
          <img src={partner.icon} alt={partner.name} width="50%" />
        </Cell>
      ))}
    </Grid>
  </SectionWrap>
);
