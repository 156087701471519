export const DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS = 450;

export const FADE_IN_TIMEOUT_MILLISECONDS = 2000;

export const PageSectionID = {
  Who: 'who',
  Roadmap: 'roadmap',
  Team: 'team',
  FAQ: 'faq',
};
