import { useEffect, useState } from 'react';
import { Colors } from '@global/colors';
import { Link } from 'react-router-dom';
import {
  CloseIconMobile, StyledIconButton,
  FlexWrap, MenuIconMobile, StyledHeaderWrap, StyledHomaGamesLogo, StyledSocials,
} from './styles';
import { SocialId } from '../../../global/components/socials/types';
import { ManifestoCTA } from './manifesto-cta';
import { HEADER_MAX_HEIGHT_MOBILE_PIXELS } from './consts';
import { SideMenuMobile } from './side-menu-mobile/side-menu-mobile';
import { sideMenuBGColor } from './side-menu-mobile/styles';

export const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isSideMenuExpanded, setIsSideMenuExpanded] = useState(false);
  const [headerBGColor, setHeaderBGColor] = useState('unset');

  const toggleSideMenuState = () => {
    setIsSideMenuExpanded(!isSideMenuExpanded);
  };

  useEffect(() => {
    const handleScroll = () => {
      const shouldStick = window.scrollY >= HEADER_MAX_HEIGHT_MOBILE_PIXELS;
      setIsSticky(shouldStick);
      setHeaderBGColor(shouldStick ? Colors.Black : 'unset');
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isSideMenuExpanded ? 'hidden' : 'auto';

    if (isSideMenuExpanded) {
      setHeaderBGColor(sideMenuBGColor);
    } else {
      setHeaderBGColor(isSticky ? Colors.Black : 'unset');
    }
  }, [isSideMenuExpanded, isSticky]);

  return (
    <>
      <StyledHeaderWrap
        sticky={isSticky}
        sx={{ background: headerBGColor }}
      >
        <FlexWrap>
          <Link to="/">
            <StyledHomaGamesLogo header />
          </Link>
          <ManifestoCTA />
        </FlexWrap>
        <FlexWrap>
          <StyledSocials
            showOnly={[SocialId.Website, SocialId.Discord, SocialId.Twitter]}
          />
          <StyledIconButton onClick={toggleSideMenuState}>
            {isSideMenuExpanded
              ? <CloseIconMobile />
              : <MenuIconMobile />}
          </StyledIconButton>
        </FlexWrap>
      </StyledHeaderWrap>
      <SideMenuMobile
        expanded={isSideMenuExpanded}
        onSelection={() => setIsSideMenuExpanded(false)}
      />
    </>
  );
};
