import {
  Routes as ReactRouterRoutes, Route, createBrowserRouter, RouterProvider,
} from 'react-router-dom';

import { Footer, Header } from '@global/components';
import { RootRoute } from './root-route/root-route';
import { ManifestoRoute } from './manifesto-route/manifesto-route';
import { TncRoute } from './tnc-route/tnc-route';

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <>
        <Header />
        <ReactRouterRoutes>
          <Route index path="/" element={<RootRoute />} />
          <Route path="/manifesto" element={<ManifestoRoute />} />
          <Route path="/tnc" element={<TncRoute />} />
        </ReactRouterRoutes>
        <Footer />
      </>
    ),
  },
]);

export const Routes = () => <RouterProvider router={router} />;
