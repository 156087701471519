import HeadlineIcon from '@global/assets/partners/headline.svg';
import BgaIcon from '@global/assets/partners/bga.png';
import EurazeoIcon from '@global/assets/partners/eurazeo.svg';
import SorareIcon from '@global/assets/partners/sorare.svg';
import PolygonIcon from '@global/assets/partners/polygon.png';
import NorthzoneIcon from '@global/assets/partners/northzone.svg';
import SpotifyIcon from '@global/assets/partners/spotify.svg';
import UnityIcon from '@global/assets/partners/unity.png';
import KingIcon from '@global/assets/partners/king.svg';

export const partners = [
  { name: 'Headline', icon: HeadlineIcon },
  { name: 'Blockchain Game Alliance', icon: BgaIcon },
  { name: 'Eurazeo', icon: EurazeoIcon },
  { name: 'Sorare', icon: SorareIcon },
  { name: 'Polygon', icon: PolygonIcon },
  { name: 'Northzone', icon: NorthzoneIcon },
  { name: 'Spotify', icon: SpotifyIcon },
  { name: 'King', icon: KingIcon },
  { name: 'Unity', icon: UnityIcon },
];
