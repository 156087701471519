import { transientProps } from '@global/utils';
import {
  styled, Box, IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '@global/colors';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';
import { Link } from 'react-router-dom';
import { HomaGamesLogo } from '../homa-games-logo/homa-games-logo';
import { HEADER_EXPAND_ANIMATION_SECONDS, HEADER_MAX_HEIGHT_MOBILE_PIXELS } from './consts';
import { Socials } from '../socials/socials';

export const FlexWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const StyledHeaderWrap = styled(
  Box,
  transientProps('sticky'),
)<{ sticky: boolean }>(
  ({ theme, sticky }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(5, 7.5),
    paddingTop: theme.spacing(sticky ? 5 : 7.75),
    height: `${sticky
      ? HEADER_MAX_HEIGHT_MOBILE_PIXELS * 1.5
      : HEADER_MAX_HEIGHT_MOBILE_PIXELS / 2}px`,
    position: sticky ? 'fixed' : 'absolute',
    transition: `height ${HEADER_EXPAND_ANIMATION_SECONDS}s cubic-bezier(0.32, 0.03, 0, 1.01), background ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS / 2}ms ease-in-out`,
    zIndex: theme.zIndex.drawer + 1,
    top: 0,
    left: 0,
    right: 0,

    [theme.breakpoints.down('md')]: {
      height: `${sticky ? HEADER_MAX_HEIGHT_MOBILE_PIXELS : HEADER_MAX_HEIGHT_MOBILE_PIXELS / 2}px`,
      padding: theme.spacing(4, 3),
    },
  }),
);

export const StyledHomaGamesLogo = styled(HomaGamesLogo)(({ theme }) => ({
  maxWidth: '111px',
  marginRight: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    maxWidth: '88px',
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '64px',
  },
}));

export const StyledManifestoCTA = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  paddingTop: theme.spacing(1),
  marginLeft: theme.spacing(-0.25),
  letterSpacing: '0.05rem',
  textDecoration: 'none',
  color: Colors.White,
  fontFamily: 'AeonikFono',
  fontWeight: theme.typography.fontWeightMedium,

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledSocials = styled(Socials)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const MenuIconMobile = styled(MenuIcon)({
  fill: Colors.White,
});

export const CloseIconMobile = styled(CloseIcon)({
  fill: Colors.SassyPink,
  padding: 0,
});
