import {
  HeroSection,
  HomaPeopleSection,
  PartnersSection,
  HomaIntro,
  HomaWork,
  FaqSection,
  HomaRoadmap,
} from './sections';

export const RootRoute = () => (
  <>
    <HeroSection />
    <HomaIntro />
    <HomaRoadmap />
    <HomaWork />
    <HomaPeopleSection />
    <PartnersSection />
    <FaqSection />
  </>
);
