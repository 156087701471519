import {
  Nerf,
  SkyRunner,
  IdleWorld,
  HomaDivider,
} from '@global/assets';
import { PageSectionID } from '@global/consts';
import {
  TextH1,
  IntroTextDescription,
  PinkTextDescription,
  StatsContainer,
  GamesHeader,
  DiscoverMoreText,
  GameCardsContainer,
  HomaWorkSectionWrapper,
  HomaDividerImage,
} from './styles';
import { SectionPartContainer, OurGamesSection } from '../../styles';
import { GameCard } from './game-card';
import { IndividualStat } from './individual-stat';
import { AnimatedGlows } from '../../animated-glows/animated-glows';

export const HomaWork = () => (
  <HomaWorkSectionWrapper id={PageSectionID.Who}>
    <HomaDividerImage src={HomaDivider} alt="divider" />
    <SectionPartContainer>
      <PinkTextDescription>WHY US?</PinkTextDescription>
      <TextH1>WE MAKE THINGS HAPPEN</TextH1>
      <IntroTextDescription>
        We are the
        <PinkTextDescription> world’s leading platform </PinkTextDescription>
        for creating and publishing mobile games on a mission to onboard 100M players into web3.
      </IntroTextDescription>
      <StatsContainer>
        <IndividualStat descriptor="Over" digitalValue="1bn" unit="Downloads" />
        <IndividualStat descriptor="Over" digitalValue="80" unit="Games published" />
        <IndividualStat descriptor="With" digitalValue="4+" unit="Stars reviews" />
      </StatsContainer>
    </SectionPartContainer>
    <SectionPartContainer />
    <OurGamesSection>
      <PinkTextDescription>OUR TOP GAMES</PinkTextDescription>
      <GamesHeader>
        <TextH1>ACCESS OUR GAMES</TextH1>
        <DiscoverMoreText>Discover 80 More &gt;</DiscoverMoreText>
      </GamesHeader>
      <GameCardsContainer>
        <GameCard
          name="Nerf- Epic Pranks!"
          imgUrl={Nerf}
          iosLink="https://apps.apple.com/us/app/nerf-epic-pranks/id1492810298"
          androidLink="https://play.google.com/store/apps/details?id=games.nerf.epic.pranks.free&hl=fr&gl=US"
          facts={[{ text1: '50 M', text2: 'Installs' }, {
            text1: '#1 US',
            text2: 'Google Play Store',
          }, { text1: '>15M', text2: 'Gameplay' }]}
        />
        <GameCard
          name="Sky Roller"
          imgUrl={SkyRunner}
          iosLink="https://apps.apple.com/us/app/sky-roller-fun-runner-game/id1478579926"
          androidLink="https://play.google.com/store/apps/details?id=com.topchopgames.skyroller&hl=en_US"
          facts={[{ text1: '150 M', text2: 'Installs' }, {
            text1: '#2 US',
            text2: 'App Store',
          }, { text1: '>12M', text2: 'Gameplay' }]}
        />
        <GameCard
          name="Idle World"
          imgUrl={IdleWorld}
          iosLink="https://apps.apple.com/us/app/idle-world/id1410042592"
          androidLink="https://play.google.com/store/apps/details?id=com.chitralekha.evolution"
          facts={[{ text1: '11 M', text2: 'Installs' }, {
            text1: 'Top 10',
            text2: 'In the UK',
          }, { text1: '#1', text2: 'In 40 countries' }]}
        />
      </GameCardsContainer>
    </OurGamesSection>
    <AnimatedGlows />
  </HomaWorkSectionWrapper>
);
