import { styled, Box } from '@mui/material';
import { pinkToBlue } from '../../colors';

export const StyledHoverOverlay = styled(Box)({
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  height: 'calc(100% + 2px)',
  width: 'calc(100% + 2px)',
  background: pinkToBlue(0.1),
  minWidth: 0,
  zIndex: -50,
});
