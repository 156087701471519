import { Colors, pinkToBlue } from '@global/colors';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';
import { theme } from '@global/theme';
import { transientProps } from '@global/utils';
import { Box, styled } from '@mui/material';

export const sideMenuBGColor = 'linear-gradient(0deg, rgba(255, 145, 250, 0.15), rgba(255, 145, 250, 0.15)), rgb(15 15 15 / 96%)';

export const SideMenuWrapper = styled(Box, transientProps('expanded'))<{ expanded: boolean }>(({ expanded, theme }) => ({
  position: 'fixed',
  right: expanded ? 0 : '-100%',
  width: '100vw',
  height: '100vh',
  padding: theme.spacing(9.5, 3, 0),
  zIndex: theme.zIndex.drawer,
  transition: `right ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms cubic-bezier(0.14, -0.19, 0.27, 1.09)`,
  background: sideMenuBGColor,

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const MenuItemWrap = styled(Box, transientProps('noBorder'))<{ noBorder: boolean }>(
  ({ noBorder }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    color: Colors.White,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: noBorder ? 'none' : '0.5px solid',
    borderImage: pinkToBlue(0.7),
    borderImageSlice: 1,
  }),
);
