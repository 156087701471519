import { Button } from '@components';
import { Colors } from '@global/colors';
import {
  SectionWrapper, TextH1, TextH2, HeaderTextWrap, RevealNowText,
} from './styles';

export const HeroSection = () => (
  <SectionWrapper>
    <HeaderTextWrap>
      <TextH1 variant="h1">ENTER OUR UNIVERSE</TextH1>
      <TextH2 variant="h2">Own a Valentine and access the Homa World.</TextH2>
    </HeaderTextWrap>
    <RevealNowText>
      Reveal Now!
    </RevealNowText>
    <Button
      sx={{ mt: 2 }}
      textColor={Colors.White}
      onClick={() => window.open('https://mintvalentine.xyz/reveal')}
    >
      Meet your Valentine
    </Button>
  </SectionWrapper>
);
