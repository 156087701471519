import { Swipe } from '@global/assets';
import { Button } from '@components';
import { IconButton } from '@mui/material';
import {
  MobileImageOverlay,
  ImageOverlayContent,
  TextDescription,
  PinkTextDescription,
  PinkCloseButtonLabel,
  SwipeGesture,
  CloseIconMobile,
  MobileStepImage,
  RoadmapStepTextAndImageDescriptionContainer,
  RoadmapStepTextDescription,
  TextH1,
  MapSubStoryContainer,
} from './styles';
import { ROADMAP_PARTS, OverlayMap } from './consts';

export const RoadmapOverlay = ({
  mapState,
  setMapState,
}: {
  mapState: ROADMAP_PARTS | null | 'init' | 'tuto',
  setMapState: (newMapState: ROADMAP_PARTS | null | 'init' | 'tuto') => void
}) => {
  if (!mapState) return null;

  return (
    <MobileImageOverlay>
      {
        mapState === 'init' && (
        <ImageOverlayContent>
          <TextH1>OUR ROADMAP</TextH1>
          <PinkTextDescription>
            Explore the 4 building blocks of the homa world:
            Funfair, Bridge, Agora, and The Gang.
          </PinkTextDescription>
          <Button onClick={() => {
            setMapState('tuto');
            setTimeout(() => {
              setMapState(null);
            }, 3000);
          }}
          >
            EXPLORE NOW
          </Button>
        </ImageOverlayContent>
        )
      }
      {
        mapState === 'tuto' && (
        <ImageOverlayContent>
          <TextDescription>Swipe left and right to explore</TextDescription>
          <SwipeGesture src={Swipe} />
        </ImageOverlayContent>
        )
      }
      {
        [
          ROADMAP_PARTS.Gang,
          ROADMAP_PARTS.Funfair,
          ROADMAP_PARTS.Bridge,
          ROADMAP_PARTS.Agora,
          ROADMAP_PARTS.Redacted,
        ].indexOf(mapState as ROADMAP_PARTS) !== -1 && (
          <MapSubStoryContainer gradientColor={OverlayMap[mapState as ROADMAP_PARTS].gradientColor}>
            <IconButton onClick={() => setMapState(null)} style={{ justifyContent: 'flex-end', padding: '24px' }}>
              <CloseIconMobile fontSize="large" />
              <PinkCloseButtonLabel>Close</PinkCloseButtonLabel>
            </IconButton>
            <RoadmapStepTextAndImageDescriptionContainer>
              <RoadmapStepTextDescription>
                {OverlayMap[mapState as ROADMAP_PARTS].description}
              </RoadmapStepTextDescription>
              <MobileStepImage src={OverlayMap[mapState as ROADMAP_PARTS].overlaySrc} />
            </RoadmapStepTextAndImageDescriptionContainer>
          </MapSubStoryContainer>
        )
      }
    </MobileImageOverlay>
  );
};
