import { LinkProps } from 'react-router-dom';
import { StyledManifestoCTA } from './styles';

export const ManifestoCTA = (props: Omit<LinkProps, 'to'>) => (
  <StyledManifestoCTA
    to="/manifesto"
    {...props}
  >
    Manifesto
  </StyledManifestoCTA>
);
