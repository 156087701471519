import { Fade } from '@mui/material';
import { HoverOverlayProps } from './types';
import { FADE_TRANSITION_TIMEOUT_MILLISECONDS } from './consts';
import { StyledHoverOverlay } from './styles';

export const HoverOverlay = ({ display, ...otherProps }: HoverOverlayProps) => (
  <Fade in={display} timeout={FADE_TRANSITION_TIMEOUT_MILLISECONDS}>
    <StyledHoverOverlay {...otherProps} />
  </Fade>
);
