import {
  alpha, Box, IconButton, styled, Typography,
} from '@mui/material';
import { Colors } from '@global/colors';
import { SectionPartContainer } from '../../styles';

export const TeamSectionContainer = styled(SectionPartContainer)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const TextH1 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: `0px 0px 30px ${alpha(Colors.SassyPink, 0.4)}`,
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(28),
  },
}));

export const TextDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  textShadow: `0px 12px 20px ${alpha(Colors.CoolDarkBlue, 0.8)}`,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export const PinkTextDescription = styled('span')(({ theme }) => ({
  color: Colors.SassyPink,
  marginBottom: theme.spacing(0.5),
}));

export const TeamTextDescription = styled(TextDescription)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  opacity: '80%',
  marginBottom: theme.spacing(6),
}));

export const TeamMemberAvatar = styled('img')(({ theme }) => ({
  width: theme.spacing(21),
  height: theme.spacing(21),
  borderRadius: theme.spacing(1.5),
}));

export const TeamMemberName = styled('h4')(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(20),
  marginTop: theme.spacing(1.5),
  lineHeight: '32px',
  color: Colors.White,
}));

export const TeamMemberRole = styled('h5')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  color: Colors.SassyPink,
  lineHeight: '16px',
}));

export const TeamMemberDescription = styled('p')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  height: '25%',
  color: Colors.White,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '20px',
  textAlign: 'center',
}));

export const TeamMemberContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'space-between',
  flex: '1 1 0',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const SocialsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const MobileView = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const NonMobileView = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const LeftCarouselArrowContainer = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(-5),
}));

export const RightCarouselArrowContainer = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(-5),
}));

export const CarouselArrow = styled('img')({
  width: '40px',
  height: '40px',
});
