import { HomaDivider } from '@global/assets';
import { InfoText } from '@global/styles';
import {
  SectionWrapper, ManifestoText,
  BackgroundDivider, StyledInfoHeading, DividerImg,
} from './styles';

export const ExplainerSection = () => (
  <>
    <SectionWrapper>
      <ManifestoText>Manifesto</ManifestoText>
      <InfoText>
        Our purpose is to give you access to a universe of
        interconnected games populated by a set of unique characters.
        Own them, make them grow and customize them to your needs,
        difference is the new norm.
      </InfoText>
      <StyledInfoHeading>
        THE HOMA FUN FAIR
      </StyledInfoHeading>
      <InfoText>
        Like all gamers lost in the matrix, you have wandered from one random game
        to another. At each new game, you had to leave your favorite character behind,
        spend money in yet another virtual currency,
        and then move on again to another gaming ecosystem.
        Over and over, like a lost soul.
        <br />
        <br />
        Take the red pill, acquire your favorite characters and look
        at a new universe opening up, full of unique experiences.
        It looks and feels like a magical funfair.
        Win a prize in a contest and spend it anywhere.
        Buy a new trendy look, it will follow you everywhere.
        Improve your power stats in one game and you will be stronger across all of them.
        And whenever you feel it is time to part ways with your old gaming
        companions, you can send them to their new adventures and sell
        them with their experiences. They are truly yours.
        <br />
        <br />
        We have a great portfolio of live playable games for you.
        On top of them, we are building new exciting, and innovative games.
        Brick by brick, we are building the Homa funfair and we will not stop anytime soon.
      </InfoText>
    </SectionWrapper>
    <BackgroundDivider />
    <SectionWrapper sx={{ pt: 2, pb: 15 }}>
      <StyledInfoHeading>
        FANTASTIC GAMES FOR EVERYONE
      </StyledInfoHeading>
      <InfoText>
        We build games with two paramount values in mind: they are fun and accessible.
        Fun is our raison d’être. It is why we exist and why we succeed.
        Our games have been downloaded over 700 million times as a result.
        <br />
        <br />
        Accessible is our mission.
        It is in our DNA to build for everyone.
        With this core value at heart, we want to create bonds across
        geographies, generations and all differences.
        Our universe will celebrate difference and will be free to enter.
      </InfoText>
      <DividerImg src={HomaDivider} alt="Divider between sections" />
      <StyledInfoHeading>
        WELCOME TO THE GANG
      </StyledInfoHeading>
      <InfoText>
        The genesis of our world is the Homa Gang, formed by memorable characters.
        Thousands of developers and artists from our
        community are crafting them to be unique and different.
        Collect them from the vast Homa Funfair population,
        create a team to represent you and make it thrive.
        <br />
        <br />
        You will meet, befriend and challenge our large community of gamers.
        You will chart your own course and experience a journey unique to you.
        You are the hero.
      </InfoText>
    </SectionWrapper>
  </>
);
