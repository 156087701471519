import { Box } from '@mui/material';
import { AppStoreDownloadButton, PlayStoreDownloadButton, FiveStarsRating } from '@global/assets';
import {
  Descriptor,
  GameCardContainer,
  GameCardHeader,
  GameFactContainer,
  GameName,
  Unit,
  AllGameFactsContainer,
  GameLogoImage,
} from './styles';

export const GameCard = ({
  name,
  imgUrl,
  iosLink,
  androidLink,
  facts,
}: { name: string;
  imgUrl: string;
  iosLink: string;
  androidLink: string;
  facts: {text1: string, text2: string}[] }) => (
    <GameCardContainer>
      <GameCardHeader>
        <GameLogoImage src={imgUrl} alt="Game logo" />
        <Box>
          <GameName>{name}</GameName>
          <img src={FiveStarsRating} alt="Game logo" />
        </Box>
      </GameCardHeader>
      <Box sx={{ display: 'flex' }}>
        <a href={iosLink}>
          <img
            src={AppStoreDownloadButton}
            alt="iOs app download button"
          />
        </a>
        <a href={androidLink}>
          <img
            src={PlayStoreDownloadButton}
            alt="iOs app download button"
          />
        </a>
      </Box>
      <AllGameFactsContainer>
        {facts.map((fact, index) => (
          <GameFactContainer key={fact.text1}>
            <Unit>{fact.text1}</Unit>
            <Descriptor>{fact.text2}</Descriptor>
          </GameFactContainer>
        ))}
      </AllGameFactsContainer>
    </GameCardContainer>
);
