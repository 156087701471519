import { alpha, keyframes } from '@mui/material';

export const hover = keyframes`
  0% { 
    transform: translateY(0%);
  }
  25%{
    transform: translateY(1.5%);
  }
  50%{
    transform: translateY(0%);
  }
  75%{
    transform: translateY(-1.5%);
  }
  100% {
    transform: translateY(0%);
  } 
`;

export const expandAndContract = (by?: number) => keyframes`
  0% { 
    transform: scale(1);
  }
  50%{
    transform: scale(${by ? 1 + by : 1.05});
  }
  100%{
    transform: scale(1);
  }
`;

export const flicker = keyframes`
  0% {
    opacity:0.1;
  }
  2% {
    opacity:1;
  }
  8% {
    opacity:0.1;
  }
  9% {
    opacity:1;
  }
  12% {
    opacity:0.1;
  }
  20% {
    opacity:1;
  }
  25% {
    opacity:0.3;
  }
  30% {
    opacity:1;
  }
  70% {
    opacity:0.7;
  }
  72% {
    opacity:0.2;
  }
  77% {
    opacity:.9;
  }
  100% {
    opacity:.9;
  }
`;

export const moveUp = keyframes`
  0% { 
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0%);
  } 
`;

export const grow = keyframes`
  0% { 
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.5);
  } 
`;

export const invalidate = keyframes`
  0% { 
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.2;
  } 
`;

export const textGlow = (color: string) => keyframes`
  0% { 
    text-shadow: 0 0 10px ${alpha(color, 0.6)}, 0 0 20px ${alpha(color, 0.6)}, 0 0 30px ${alpha(color, 0.6)}, 0 0 40px ${color},
    0 0 70px ${color}, 0 0 80px ${color}, 0 0 100px ${color}, 0 0 150px ${color};
  }
  100% {
    text-shadow: 0 0 5px ${alpha(color, 0.6)}, 0 0 10px ${alpha(color, 0.6)}, 0 0 15px ${alpha(color, 0.6)}, 0 0 20px ${color},
    0 0 35px ${color}, 0 0 40px ${color}, 0 0 50px ${color}, 0 0 75px ${color};
  } 
`;

export const leftToRight = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`;

export const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const swipe = keyframes`
  30% { transform: rotate(-45deg); }
  100% { transform: rotate(45deg); }
`;
