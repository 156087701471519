import { Box } from '@mui/material';
// import { Newsletter } from './newsletter';
import { Link } from 'react-router-dom';
import {
  MainContentWrap, LogoAndSocialsWrap,
  CopyrightText, StyledWrap, PrivacyPolicyAndTermsWrap,
  StyledHomaGamesLogo, SocialsNonMobile, hideInMobile,
} from './styles';
import { SocialsMobile } from './socials-mobile';
import { Space } from './space';

export const Footer = () => (
  <StyledWrap>
    <MainContentWrap>
      <LogoAndSocialsWrap>
        <StyledHomaGamesLogo />
        <SocialsNonMobile />
        <SocialsMobile />
      </LogoAndSocialsWrap>
      {/* <Newsletter /> */}
    </MainContentWrap>

    <PrivacyPolicyAndTermsWrap>
      <a href="https://www.homagames.com/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      <Space />
      <Link to="/tnc" target="_blank">
        Terms
      </Link>
    </PrivacyPolicyAndTermsWrap>
    <CopyrightText>
      HomaGames ©️ 2022 All Rights Reserved
      <Space sx={hideInMobile} />
      <Box
        component="span"
        sx={hideInMobile}
      >
        Powered by Polygon
      </Box>
    </CopyrightText>
  </StyledWrap>
);
