import { TwitterIcon, DiscordIcon } from '@global/assets';
import { PageSectionID } from '@global/consts';

export const menuItems = [
  {
    name: 'Manifesto',
    url: '/manifesto',
  },
  {
    name: 'Terms & Conditions',
    url: '/tnc',
  },
  {
    name: 'Who are we?',
    url: `#${PageSectionID.Who}`,
  },
  {
    name: 'Roadmap',
    url: `#${PageSectionID.Roadmap}`,
  },
  {
    name: 'Meet the team',
    url: `#${PageSectionID.Team}`,
  },
  {
    name: 'FAQ',
    url: `#${PageSectionID.FAQ}`,
  },
  {
    name: 'Twitter',
    icon: TwitterIcon,
    url: 'https://twitter.com/HomaGames',
  },
  {
    name: 'Discord',
    icon: DiscordIcon,
    url: 'https://discord.gg/homagang',
  },
];
