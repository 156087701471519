import { Typography } from '@mui/material';
import { SideMenuWrapper, MenuItemWrap } from './styles';
import { SideMenuMobileProps } from './types';
import { menuItems } from './consts';
import { urlTarget } from './utils/url-target';

export const SideMenuMobile: React.FC<SideMenuMobileProps> = ({ onSelection, ...props }) => (
  <SideMenuWrapper {...props}>
    {menuItems.map((item, idx) => (
      <MenuItemWrap
        key={item.name}
        noBorder={idx === menuItems.length - 1}
      >
        <Typography
          component="a"
          onClick={onSelection}
          href={item.url}
          target={urlTarget(item.url)}
        >
          {item.name}
        </Typography>
        {item.icon && <img src={item.icon} alt={item.name} />}
      </MenuItemWrap>
    ))}
  </SideMenuWrapper>
);
