import {
  alpha, Box, styled, Typography,
} from '@mui/material';
import ValentineGiftBGDesktop from '@asset/backgrounds/valentine-gift-desktop.jpeg';
import ValentineGiftBGMobile from '@asset/backgrounds/valentine-gift-mobile.jpeg';
import { Colors } from '@global/colors';

export const SectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: `url(${ValentineGiftBGDesktop})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${ValentineGiftBGMobile})`,
  },
}));

export const HeaderTextWrap = styled(Box)(({ theme }) => ({
  color: Colors.White,
  textAlign: 'center',
  paddingTop: '10%',

  [theme.breakpoints.down('sm')]: {
    paddingTop: '60%',
  },
}));

export const TextH1 = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(96),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: `0px 12px 20px ${alpha(Colors.CoolDarkBlue, 0.6)}`,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(44),
  },
}));

export const TextH2 = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(8),
  fontSize: theme.typography.pxToRem(28),
  fontWeight: theme.typography.fontWeightRegular,
  width: '60%',
  marginLeft: '20%',
  marginRight: '20%',
  textShadow: `0px 12px 20px ${alpha(Colors.CoolDarkBlue, 0.8)}`,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(16),
  },
}));
