import { Box, styled } from '@mui/material';
import { Colors, pinkToBlue } from '@global/colors';
import HomaNewBrandBanner from '@global/assets/backgrounds/new-brand-banner.png';
import { InfoText } from '@global/styles';

export const SectionWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  background: 'linear-gradient(to right, rgba(15, 15, 15, 1), rgba(47, 33, 47, 1))',

  [theme.breakpoints.up('sm')]: {
    minHeight: '95vh',
  },
}));

export const Banner = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${HomaNewBrandBanner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '55vh',

  [theme.breakpoints.down('sm')]: {
    height: '20vh',
  },
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: Colors.RoyalDarkPurple,
  border: '1px solid',
  borderImage: pinkToBlue(),
  borderImageSlice: 1,
  width: '44%',
  marginLeft: '28%',
  marginRight: '28',
  padding: theme.spacing(3, 6),

  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    padding: theme.spacing(4, 2),
    marginTop: theme.spacing(-4),
  },
}));

export const StyledInfoText = styled(InfoText)(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(24),
  fontWeight: theme.typography.fontWeightMedium,
  opacity: 0.7,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(16),
  },
}));
